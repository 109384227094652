import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#F5B219" d="M557.448,594.521c3.464,8.164,8.243,11.092,20.822,15.617
		c32.372,11.646,114.366,13.217,145.755,5.205c14.349-3.66,19.655-8.22,23.425-18.219c59.511,16.222,117.172,46.865,132.741,106.714
		c13.838,53.189,0,133.997,0,203.016c0,69.259-1.709,143.191,5.206,203.015c15.245,131.893,89.699,223.343,98.905,327.952
		c10.345,117.56-55.923,230.284-127.537,265.482c-66.443,32.658-147.343,19.938-249.864,15.616
		c-59.936-2.526-124.222,9.009-166.578,2.603c-86.979-13.15-172.431-114.735-182.194-218.633
		c-14.328-152.475,77.948-241.714,104.11-380.006c13.568-71.721,19.458-136.737,26.028-216.03
		c7.036-84.89,3.751-199.783,41.645-247.263C458.743,623.461,504.884,611.05,557.448,594.521z"/>
                <path fill="#3D2316" d="M697.444,560.472L690.242,483l-4.813,7.406L676.17,483l-5.184,14.072L662.839,483l-4.813,14.072
		L649.139,483l-3.333,14.998L636.919,483l-6.11,14.998L621,483l-9.006,77.461c-33.858,6.94-57.283,21.876-57.283,39.185
		c0,23.928,44.763,28.883,99.982,28.883c55.219,0,99.981-4.956,99.981-28.883C754.675,582.346,731.275,567.417,697.444,560.472z"/>
            </g>
        </SvgIcon>
    )
}