import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#9B0824" d="M843.219,746.647c-45.352-16.075-140.748-20.121-146.118-54
			c-4.944-31.194,30.858-67.238,47.647-88.942c23.262-30.071,50.59-55.287,57.177-76.235c-37.734,7.81-67.804,40.178-88.942,54
			c7.531-23.616,12.638-76.648-3.176-95.294c-11.299,39.537-36.939,81.633-60.353,98.47c-1.448-40.905-7.627-77.079-25.411-101.647
			c-0.586,3.766-1.232,7.468-1.912,11.138v1212.165c9.567-9.189,5.361-32.154,17.793-38.479
			c8.559,30.618,22.299,56.055,60.354,57.177c-6.277-14.607-37.439-46.232-22.235-82.589c18.694-16.669,36.778-10.729,60.353-12.705
			c222.807-18.672,367.545-222.085,381.178-441.529C1134.441,948.796,1007.129,804.747,843.219,746.647z"/>
                    <path fill="#BD001D" d="M589.101,584.647c-26.895-26.047-42.622-63.26-60.353-98.47c-10.329,25.71-7.681,72.201,0,98.47
			c-24.331-24.374-51.03-46.381-88.941-57.176c-2.951,8.544,11.28,22.162,22.234,34.941C482.125,585.84,557.308,655.739,544.63,699
			c-8.101,27.643-119.526,35.737-158.824,50.824c-150.67,57.843-252.635,181.191-263.647,374.823
			c-8.238,144.873,40.549,256.851,95.294,333.53c6.493,9.094,25.956,22.9,25.412,22.235c7.324,8.972,8.65,20.487,15.882,28.588
			c53.743,60.198,146.633,113.604,250.941,120.706c29.307,1.995,39.427-3.415,54,15.883c18.463,35.021-24.129,68.363-19.059,79.411
			c38.416-1.82,48.134-32.336,60.354-60.353c2.905,16.153,11.129,26.988,12.705,44.471c1.757-0.662,3.209-1.629,4.442-2.815V494.138
			C615.701,528.888,605.008,559.375,589.101,584.647z"/>
                </g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#E54160" d="M266.073,963.173c0,0-142.558,189.278-33.764,349.742
		C341.104,1473.382,187.291,1090.726,266.073,963.173z"/>
            </g>
        </SvgIcon>
    )
}