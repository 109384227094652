import React from 'react';
import { SvgIcon } from '@mui/material';


export default function (props) {
    return (
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="none" stroke="#C4D178" strokeWidth="80" strokeLinecap="round" strokeLinejoin="round"
                      strokeMiterlimit="10" d="
		M565.266,1177.162c0,0-97.64,306.834,29.353,515.997"/>
                <path fill="#2F961D" d="M68.065,747.704c0,0,123.187,225.339,168.254,258.39c45.068,33.05-15.022-96.146-15.022-96.146
		s108.164,202.666,165.249,217.008c57.086,14.342-12.018-69.786-12.018-69.786s54.081,93.141,177.267,87.132
		c123.186-6.01,78.118,66.099,78.118,66.099s-120.182-18.026-156.236,21.031c-36.054,39.06-66.1,99.15-66.1,99.15
		s48.073-114.171-24.036-132.199c-72.108-18.027-90.136,6.008-132.199,24.035c-42.063,18.028,63.096-55.723,18.027-95.463
		c-45.068-39.741-153.231-36.736-153.231-36.736s120.182-3.005,75.114-51.077c-45.068-48.072-75.114-30.045-75.114-30.045
		s72.108-18.027,33.05-66.1C110.128,894.926,68.065,747.704,68.065,747.704z"/>
                <path fill="#2DAE1E" d="M1173.936,747.704c0,0-123.186,225.339-168.256,258.39c-45.066,33.05,15.023-96.146,15.023-96.146
		s-108.162,202.667-165.248,217.007c-57.086,14.342,12.018-69.785,12.018-69.785s-54.082,93.141-177.266,87.131
		c-123.188-6.009-78.118,66.101-78.118,66.101s120.181-18.027,156.235,21.031c36.053,39.06,66.1,99.149,66.1,99.149
		s-48.072-114.171,24.035-132.199c72.109-18.026,90.137,6.009,132.199,24.036s-63.096-55.723-18.027-95.464
		c45.068-39.74,153.232-36.735,153.232-36.735s-120.184-3.005-75.113-51.077c45.066-48.072,75.113-30.045,75.113-30.045
		s-72.109-18.027-33.051-66.1S1173.936,747.704,1173.936,747.704z"/>
                <g>
                    <path fill="#2F961D" d="M662.271,483c0,0,97.936,237.405,95.338,293.232c-2.598,55.826-65.367-72.088-65.367-72.088
			s89.734,211.472,65.031,264.897c-24.705,53.425-46.748-53.191-46.748-53.191s38.494,100.59-43.568,192.658
			c-82.063,92.07,2.354,102.305,2.354,102.305s61.475-104.832,114.514-108.346s118.666,10.859,118.666,10.859
			s-119.029-34.322-87.758-101.75c31.275-67.429,61.301-66.356,101.752-87.758c40.449-21.402-82.994,14.085-85.6-45.945
			c-2.607-60.031,67.68-142.298,67.68-142.298s-77.838,91.619-86.928,26.354c-9.09-65.264,23.811-77.316,23.811-77.316
			s-59.326,44.78-72.191-15.81C750.391,608.214,662.271,483,662.271,483z"/>
                </g>
                <path fill="#2DAE1E" d="M432.996,1574.536c0,0-17.387-148.987-7.071-179.956c10.318-30.967,25.309,50.894,25.309,50.894
		s-16.891-133.109,5.471-159.221c22.361-26.111,17.837,37.316,17.837,37.316s-5.679-62.649,55.056-101.385
		c60.734-38.733,14.908-57.878,14.908-57.878s-51.187,49.176-81.558,42.733c-30.372-6.438-64.981-24.929-64.981-24.929
		s61.463,38.176,33.187,71.117c-28.277,32.94-44.985,27.575-71.118,33.187c-26.133,5.613,48.887,5.249,40.828,39.407
		c-8.06,34.157-60.621,69.25-60.621,69.25s58.289-39.151,53.045-1.021c-5.245,38.126-25.65,39.681-25.65,39.681
		s40.452-15.758,38.071,20.341C403.329,1490.172,432.996,1574.536,432.996,1574.536z"/>
                <path fill="#2F961D" d="M704.252,1725c0,0,23.764-203.633,9.664-245.96c-14.104-42.325-34.592,69.56-34.592,69.56
		s23.086-181.931-7.479-217.619c-30.563-35.688-24.379,51.003-24.379,51.003s7.762-85.628-75.252-138.57
		c-83.009-52.94-20.375-79.106-20.375-79.106s69.962,67.212,111.472,58.407c41.512-8.8,88.814-34.072,88.814-34.072
		s-84.006,52.179-45.359,97.201c38.648,45.022,61.484,37.689,97.205,45.359c35.717,7.673-66.818,7.174-55.805,53.86
		c11.016,46.686,82.855,94.649,82.855,94.649s-79.668-53.51-72.5-1.396c7.168,52.11,35.059,54.235,35.059,54.235
		s-55.289-21.538-52.035,27.801C744.799,1609.692,704.252,1725,704.252,1725z"/>
                <path fill="#C3A3BA" d="M800.578,952.814c1.285,19.632,0.67,38.334,5.238,47.781c-9.189,25.946-13.191,42.639,6.508,49.358
		c-30.686,24.505-37.666,39.039-29.35,74.34c-24.844-8.54-34.391,3.688-47.064,34.291c-8.572-8.604-20.271,1.171-33.992,12.244
		c-5.711,2.935-11.928,6.196-18.479,9.448c-6.551,3.25-13.439,6.493-16.881,10.325c-13.018,2.93-22.717,6.184-19.801,15.364
		c-22.787-3.941-32.957-1.953-27.208,16.688c-26.436-15.978-36.953-15.301-43.523,11.374c-20.005-26.675-35.94-28.487-69.025-15.892
		c1.822-19.477-13.508-24.547-36.37-28.943c-6.606-8.638-20.745-16.604-33.996-25.833c-13.251-9.229-25.615-19.724-33.651-15.357
		c-14.331-27.521-24.334-40.182-44.634-18.773c3.238-48.529-3.82-62.991-48.507-79.889c34.1-13.461,30.572-29.359,12.116-64.219
		c7.757,0.995,11.782-2.75,14.146-9.107c2.37-6.361,3.077-15.335,0.399-22.124c5.269-8.497,6.692-14.665-1.194-16.267
		c10.812-11.008,12.315-17.449-1.327-19.725c18.529-16.828,22.29-29.271,6.56-47.352c24.243-6.061,28.996-17.758,22.734-42.3
		c15.433,4.861,19.638-3.319,18.954-20.827c7.958,2.073,11.024-5.183,11.882-14.823c9.175-4.927,16.14-12.238,19.422-20.017
		c3.281-7.777,2.878-16.021-3.48-25.869c37.028-3.764,50.232-13.94,51.517-51.42c13.585,10.384,24.191,13.943,34.274,11.858
		s19.645-9.812,30.354-22.606c5.541,8.349,12.229,11.631,12.738,6.713c15.457,7.197,24.732,6.194,23.86-9.464
		c19.095,15.659,27.607,16.52,29.52-5.474c15.109,24.191,23.617,25.527,36.9,2.038c2.01,26.694,8.998,28.59,28.146,8.676
		c-7.859,20.543-3.559,19.276,10.842-0.663c-1.574,25.564,6.955,32.455,27.42,26.798c-7.014,7.429-2.092,2.31,6.014-13.979
		c0.691,17.173,5.191,27.938,14.563,34.659c1.318,8.155,7.51,12.266,15.893,13.534c8.385,1.268,18.961-0.309,33.078-4.246
		c-23.125,33.181-14.395,45.117,23.248,68.234c-22.098,3.219-15.283,17.621-3.326,38.376c-0.395,10.484,4.354,27.322,7.988,45.362
		C796.719,913.145,799.244,932.386,800.578,952.814z"/>
                <path fill="#A56596" d="M414.05,1090.348c-9.988,0-18.33-11.871-20.346-24.145c-0.304,1.852-0.468,3.772-0.468,5.742
		c0,15.032,9.319,29.354,20.814,29.354s20.814-14.321,20.814-29.354c0-1.97-0.164-3.89-0.468-5.742
		C432.379,1078.477,424.038,1090.348,414.05,1090.348z"/>
                <path fill="#A56596" d="M562.017,1156.699c-13.779,0-25.287-16.377-28.068-33.308c-0.42,2.555-0.646,5.204-0.646,7.922
		c0,20.737,12.855,40.494,28.714,40.494c15.858,0,28.713-19.757,28.713-40.494c0-2.718-0.226-5.367-0.646-7.922
		C587.303,1140.322,575.796,1156.699,562.017,1156.699z"/>
                <path fill="#A56596" d="M550.518,978.674c-10.238,0-18.789-12.168-20.855-24.749c-0.312,1.898-0.48,3.866-0.48,5.886
		c0,15.408,9.553,30.089,21.336,30.089c11.783,0,21.335-14.681,21.335-30.089c0-2.02-0.168-3.988-0.48-5.886
		C569.307,966.506,560.756,978.674,550.518,978.674z"/>
                <path fill="#A56596" d="M590.731,872.337c-13.159,0-24.149-15.639-26.805-31.809c-0.401,2.44-0.617,4.969-0.617,7.565
		c0,19.804,12.277,38.672,27.422,38.672s27.422-18.868,27.422-38.672c0-2.596-0.216-5.125-0.617-7.565
		C614.88,856.698,603.89,872.337,590.731,872.337z"/>
                <path fill="#A56596" d="M526.126,905.69c-6.794,0-12.469-8.075-13.84-16.424c-0.207,1.26-0.319,2.566-0.319,3.906
		c0,10.226,6.339,19.968,14.159,19.968c7.819,0,14.158-9.742,14.158-19.968c0-1.34-0.111-2.646-0.318-3.906
		C538.595,897.615,532.92,905.69,526.126,905.69z"/>
                <path fill="#A56596" d="M463.578,1010.264c-13.779,0-25.286-16.376-28.067-33.308c-0.42,2.555-0.646,5.204-0.646,7.922
		c0,20.737,12.856,40.494,28.714,40.494s28.714-19.757,28.714-40.494c0-2.718-0.226-5.367-0.646-7.922
		C488.864,993.887,477.356,1010.264,463.578,1010.264z"/>
                <path fill="#A56596" d="M708.816,910.473c-13.779,0-25.287-16.376-28.068-33.308c-0.418,2.555-0.645,5.204-0.645,7.922
		c0,20.737,12.855,40.494,28.713,40.494s28.713-19.756,28.713-40.494c0-2.718-0.227-5.367-0.645-7.922
		C734.104,894.096,722.596,910.473,708.816,910.473z"/>
                <path fill="#A56596" d="M492.292,787.509c-13.779,0-25.287-16.376-28.068-33.308c-0.419,2.555-0.646,5.204-0.646,7.922
		c0,20.737,12.855,40.494,28.713,40.494c15.857,0,28.713-19.756,28.713-40.494c0-2.718-0.226-5.367-0.646-7.922
		C517.578,771.133,506.07,787.509,492.292,787.509z"/>
                <path fill="#A56596" d="M680.104,1131.003c-11.525,0-21.15-13.697-23.477-27.858c-0.35,2.137-0.539,4.353-0.539,6.626
		c0,17.344,10.752,33.868,24.016,33.868c13.262,0,24.016-16.524,24.016-33.868c0-2.273-0.191-4.489-0.541-6.626
		C701.252,1117.306,691.627,1131.003,680.104,1131.003z"/>
                <path fill="#A56596" d="M744.691,1052.462c-12.531,0-22.998-14.895-25.527-30.295c-0.381,2.324-0.588,4.733-0.588,7.205
		c0,18.861,11.693,36.831,26.115,36.831c14.424,0,26.117-17.97,26.117-36.831c0-2.472-0.207-4.881-0.588-7.205
		C767.691,1037.567,757.225,1052.462,744.691,1052.462z"/>
                <path fill="#A56596" d="M611.817,1072.497c-9.529,0-17.487-11.326-19.41-23.035c-0.291,1.767-0.446,3.599-0.446,5.479
		c0,14.34,8.89,28.003,19.856,28.003c10.966,0,19.857-13.663,19.857-28.003c0-1.88-0.156-3.712-0.447-5.479
		C629.303,1061.171,621.345,1072.497,611.817,1072.497z"/>
                <path fill="#A56596" d="M757.387,973.975c-9.527,0-17.486-11.326-19.41-23.035c-0.289,1.767-0.445,3.599-0.445,5.479
		c0,14.34,8.891,28.003,19.855,28.003c10.967,0,19.857-13.663,19.857-28.003c0-1.88-0.156-3.711-0.447-5.479
		C774.873,962.649,766.916,973.975,757.387,973.975z"/>
                <path fill="#A56596" d="M477.019,1133.199c-6.324,0-11.604-7.516-12.881-15.287c-0.192,1.173-0.296,2.389-0.296,3.637
		c0,9.516,5.9,18.584,13.178,18.584s13.178-9.068,13.178-18.584c0-1.248-0.104-2.464-0.297-3.637
		C488.623,1125.684,483.342,1133.199,477.019,1133.199z"/>
                <path fill="#A56596" d="M571.854,751.88c-6.324,0-11.604-7.516-12.881-15.287c-0.192,1.173-0.296,2.388-0.296,3.636
		c0,9.516,5.9,18.583,13.178,18.583s13.178-9.067,13.178-18.583c0-1.248-0.104-2.463-0.297-3.636
		C583.458,744.364,578.177,751.88,571.854,751.88z"/>
                <path fill="#A56596" d="M396.361,959.821c-11.028,0-20.239-13.108-22.465-26.659c-0.336,2.044-0.517,4.165-0.517,6.341
		c0,16.597,10.289,32.41,22.982,32.41c12.691,0,22.981-15.813,22.981-32.41c0-2.176-0.181-4.296-0.518-6.341
		C416.599,946.713,407.388,959.821,396.361,959.821z"/>
                <path fill="#A56596" d="M666.549,990.716c-9.529,0-17.486-11.326-19.412-23.035c-0.289,1.767-0.445,3.599-0.445,5.479
		c0,14.34,8.891,28.003,19.857,28.003c10.965,0,19.855-13.663,19.855-28.003c0-1.88-0.156-3.711-0.447-5.479
		C684.033,979.391,676.076,990.716,666.549,990.716z"/>
                <path fill="#A56596" d="M470.52,869.941c-9.529,0-17.487-11.326-19.411-23.035c-0.29,1.767-0.446,3.599-0.446,5.479
		c0,14.34,8.89,28.003,19.857,28.003c10.966,0,19.857-13.664,19.857-28.003c0-1.88-0.157-3.712-0.448-5.479
		C488.006,858.615,480.048,869.941,470.52,869.941z"/>
                <path fill="#A56596" d="M646.691,801.444c-9.529,0-17.486-11.326-19.41-23.035c-0.291,1.767-0.447,3.599-0.447,5.479
		c0,14.34,8.891,28.003,19.857,28.003c10.965,0,19.857-13.663,19.857-28.003c0-1.88-0.158-3.711-0.449-5.479
		C664.178,790.119,656.219,801.444,646.691,801.444z"/>
                <path fill="#A56596" d="M510.395,1080.154c-12.697,0-23.3-15.091-25.863-30.691c-0.387,2.354-0.595,4.794-0.595,7.299
		c0,19.108,11.846,37.313,26.458,37.313c14.612,0,26.458-18.205,26.458-37.313c0-2.505-0.208-4.946-0.594-7.299
		C533.695,1065.063,523.091,1080.154,510.395,1080.154z"/>
            </g>
        </SvgIcon>
    )
}