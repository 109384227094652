import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#FF5722" d="M1229,1088.173c0,333.581-272.211,604-608,604s-608-270.42-608-604
		c0-103.625,26.268-201.154,72.551-286.376C188.257,612.68,389.521,632.173,621,632.173c224.949,0,421.367-26.64,526.525,153.769
		C1199.342,874.835,1229,978.063,1229,1088.173z"/>
                <path fill="#6AA524" d="M462.87,587.392c0,0-133.406,4.201-193.282,53.572c0,0,4.202,90.338,81.935,96.641
		c77.733,6.303,99.793-6.302,99.793-6.302s-51.473,53.573,11.555,89.288c63.026,35.714,88.237,58.825,89.287,79.833
		c0,0,31.514-42.018,72.481-49.371c40.967-7.353,89.288-28.362,102.943-77.733c0,0,7.354-31.513,86.137-18.908
		c78.783,12.606,174.375-63.026,156.516-139.709c0,0-64.076,9.454-120.801-8.403c-56.725-17.858-105.045-13.656-123.951,4.202
		c0,0-15.758-46.22-38.867-56.724s-36.766-37.951-36.766-37.951s-111.348,19.043-129.205,48.456s8.403,50.421,14.706,69.33
		c6.303,18.907-7.354,45.168-17.857,12.605C506.988,613.653,501.736,590.543,462.87,587.392z"/>
                <path fill="#4D681F" d="M542.116,662.396c0,0-61.258,1.929-88.751,24.599c0,0,1.93,41.481,37.623,44.375
		c35.692,2.894,45.822-2.894,45.822-2.894s-23.635,24.599,5.306,40.999s40.517,27.011,40.998,36.658c0,0,14.471-19.293,33.281-22.67
		c18.812-3.376,40.999-13.023,47.271-35.693c0,0,3.376-14.47,39.551-8.682c36.176,5.789,80.068-28.94,71.869-64.151
		c0,0-29.423,4.341-55.469-3.858c-26.047-8.2-48.234-6.271-56.916,1.929c0,0-7.235-21.223-17.848-26.046
		c-10.611-4.823-16.881-17.426-16.881-17.426s-51.129,8.744-59.328,22.25c-8.2,13.506,3.858,23.152,6.753,31.835
		c2.894,8.682-3.377,20.74-8.2,5.788C562.374,674.454,559.962,663.842,542.116,662.396z"/>
            </g>
        </SvgIcon>
    )
}