import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#6AC943" d="M667.579,722.182c0.951-14.147,1.47-28.865,1.47-44.007c0-107.792-56.922-195.175-56.922-195.175
		s-56.921,87.383-56.921,195.175c0,15.142,0.519,29.859,1.47,44.007C498.349,633.724,389.801,553.835,188.03,575.53
		c0,0,251.699,89.892,292.152,346.087c32.054,203.021,103.615,84.288,131.944,26.249c28.33,58.039,99.891,176.771,131.945-26.249
		c40.453-256.195,292.151-346.087,292.151-346.087C834.453,553.835,725.906,633.727,667.579,722.182z"/>
                <g>
                    <path fill="#DB0239" d="M556.428,792.735c-60.349,0-111.291,40.148-127.65,95.184c-21.192-14.229-46.693-22.538-74.139-22.538
			c-73.555,0-133.182,59.627-133.182,133.18c0,21.861,5.302,42.476,14.638,60.677c-70.982,2.908-127.64,61.344-127.64,133.039
			c0,73.555,59.627,133.182,133.181,133.182c3.595,0,7.144-0.185,10.666-0.463c-19.252,23.094-30.845,52.796-30.845,85.213
			c0,65.301,47.01,119.585,109.023,130.954c-0.014,0.744-0.057,1.479-0.057,2.227c0,73.555,59.627,133.182,133.181,133.182
			c16.902,0,33.053-3.182,47.929-8.924c24.048,34.648,64.096,57.354,109.466,57.354h0.002V809.477
			C601.869,798.84,579.871,792.735,556.428,792.735z"/>
                    <path fill="#B71342" d="M685.574,792.735c60.348,0,111.289,40.148,127.648,95.184c21.194-14.229,46.694-22.538,74.14-22.538
			c73.555,0,133.181,59.627,133.181,133.18c0,21.861-5.301,42.476-14.636,60.677c70.981,2.908,127.638,61.344,127.638,133.039
			c0,73.555-59.625,133.182-133.18,133.182c-3.595,0-7.145-0.185-10.665-0.463c19.249,23.094,30.843,52.796,30.843,85.213
			c0,65.301-47.008,119.585-109.022,130.954c0.014,0.744,0.059,1.479,0.059,2.227c0,73.555-59.629,133.182-133.183,133.182
			c-16.901,0-33.053-3.182-47.928-8.924C706.421,1702.295,666.371,1725,621.003,1725h-0.002V809.477
			C640.132,798.84,662.131,792.735,685.574,792.735z"/>
                </g>
            </g>
        </SvgIcon>
    )
}