import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M395.572,776.807c6.806-7.662,27.728-1.208,40.065-3.339
		C428.831,781.13,407.91,774.676,395.572,776.807z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M115.121,1381.113c0-4.452,0-8.902,0-13.354
		c4.399-125.382,17.155-229.032,63.436-330.533c42.089-92.308,99.011-186.045,203.661-186.967
		c59.734,121.016-106.124,199.921-113.516,303.822c-71.003,89.253-98.397,222.12-143.564,337.21
		C117.159,1459.203,121.527,1414.771,115.121,1381.113z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M1123.412,1043.903c-61.223-39.404-153.251-36.015-247.066-46.742
		c-21.037-2.406-48.227-10.025-50.081-26.709c-4.293-38.64,75.9-43.871,110.178-40.065c60.781,6.749,143.757,56.126,180.29,96.823
		C1119.041,1029.78,1133.336,1039.38,1123.412,1043.903z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M295.411,1551.387c46.834-89.802,120.726-243.962,150.242-383.951
		c15.017-71.22,10.102-237.165,90.145-227.032c62.764,7.945,73.533,95.742,73.452,163.597c4.562,14.577-12.928,7.104-10.017,20.033
		C576.828,1345.353,432.901,1445.153,295.411,1551.387z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M779.523,1063.936c9.649,4.818-6.208,35.145,16.693,26.71
		c-0.804,5.873-5.745,7.61-13.354,6.677c59.736,263.561-115.333,507.033-247.065,627.678
		c46.929-121.871,95.335-297.676,103.5-477.436c3.219-70.863-13.01-184.606,26.71-223.693
		c35.452-34.888,90.953-2.342,100.161,43.403C771.455,1066.996,778.903,1068.88,779.523,1063.936z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#98805E" d="M1046.621,1033.888c7.766,3.364,10.766,11.492,10.016,23.371
		c-13.918-1.676-14.725,9.757-30.049,6.677c-4.837-8.356-8.085-13.111-3.339-23.371
		C1032.695,1039.993,1046.154,1043.438,1046.621,1033.888z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M889.7,1104c2.84-0.613,3.15,1.302,3.34,3.339
		c-2.743,12.626,16.098,4.557,13.354-3.339c45.093,49.191,82.938,104.333,90.146,186.969
		c12.669,145.27-44.645,280.059-93.484,370.596c0.993-73.501,4.355-139.551,0-217.016c-5.375-95.603-37.455-174.624-50.08-250.403
		C845.975,1152.133,859.094,1122.258,889.7,1104z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M191.911,483c27.125,57.047,43.788,114.208,80.129,166.936
		c38.31,41.819,63.529,96.729,120.193,120.194c0,4.452,0,8.903,0,13.354C444.409,822.566,496.345,861.889,552.492,897
		c12.241,0,24.483,0,36.727,0c10.626,4.6-11.799,3.304-16.694,3.339c26.921,32.391,92.425,48.207,136.886,73.451
		c24.856,14.114-4.149-0.986-20.031,3.339c-42.937-34.967-105.5-50.308-150.242-83.468c-6.701-1.136-10.447,0.683-10.017,6.678
		c-8.604-4.752-4.999-21.711-16.693-23.371c-45.008-18.428-71.88-54.992-116.855-73.452
		C317.868,727.348,237.123,647.83,195.25,526.403C191.345,515.081,173.878,493.122,191.911,483z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M849.636,583.162c-51.352,81.853-151.894,201.881-283.789,217.016
		c-41.728,4.788-104.89-3.269-120.194-30.048c-7.386-48.928,45.128-56.379,80.13-63.436
		C648.379,681.978,768.572,644.658,849.636,583.162z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M1110.057,923.71c-73.016-18.869-160.159-34.456-260.421-30.049
		c-83.382,3.666-163.777,32.829-210.338-3.338c11.096-133.234,252.242-93.964,353.903-46.742
		c37.145,17.254,70.541,37.097,100.162,60.097C1099.736,908.627,1118.893,912.579,1110.057,923.71z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#7AC465" d="M802.894,993.823c0.375,7.367-25.549,5.186-13.354,13.354
		c-4.683,9.726-11.503-5.919-20.032-3.339C778.694,998.559,792.674,998.069,802.894,993.823z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#98805E" d="M1023.249,1100.662c-2.876-10.479-16.7-10.011-13.354-26.711
		c10.202-7.764,28.289-1.1,36.727,3.34c5.557,10.486-12.418,13.643-3.338,16.693
		C1043.574,1099.79,1022.957,1087.177,1023.249,1100.662z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#98805E" d="M1003.218,1147.403c5.561-6.634-0.302-4.245,0-13.354
		c-35.191-0.954-13.08-29.731,6.677-30.049c15.412-0.943-3.885,32.821,16.693,26.71c2.857,7.788-11.675,14.385-3.339,16.693
		C1022.876,1153.804,1012.069,1143.482,1003.218,1147.403z"/>
            </g>
        </SvgIcon>
    )
}