import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <g>
                        <path fill="#3E7F00" d="M693.91,1436.826c-24.853,0-45-20.147-45-45V505.699c0-24.853,20.147-45,45-45s45,20.147,45,45v886.127
				C738.91,1416.679,718.763,1436.826,693.91,1436.826z"/>
                    </g>
                    <g>
                        <path fill="#7AB23F" d="M693.91,744.787c-11.935,0-23.381-4.741-31.82-13.18L487.387,556.903
				c-17.574-17.573-17.574-46.065,0-63.64c17.573-17.573,46.066-17.573,63.64,0L693.91,636.147l142.883-142.884
				c17.574-17.573,46.067-17.573,63.64,0c17.574,17.574,17.574,46.066,0,63.64L725.73,731.607
				C717.291,740.046,705.845,744.787,693.91,744.787z"/>
                    </g>
                    <g>
        §                <path fill="#7AB23F" d="M693.91,997.01c-11.517,0-23.033-4.394-31.819-13.181L353.379,675.118
				c-17.574-17.573-17.574-46.065,0-63.64c17.573-17.573,46.065-17.573,63.64,0L693.91,888.37l276.892-276.892
				c17.572-17.573,46.065-17.573,63.64,0c17.573,17.574,17.573,46.066,0,63.64L725.729,983.829
				C716.943,992.616,705.427,997.01,693.91,997.01z"/>
                    </g>
                    <g>
                        <path fill="#7AB23F" d="M693.909,1254.588c-11.935,0-23.381-4.741-31.82-13.181L209.656,788.973
				c-17.574-17.573-17.573-46.066,0-63.64s46.066-17.573,63.64,0l420.613,420.615l420.614-420.615
				c17.574-17.573,46.066-17.573,63.641,0c17.573,17.574,17.573,46.066,0,63.64l-452.435,452.435
				C717.29,1249.847,705.844,1254.588,693.909,1254.588z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill="#3E7F00" d="M95.606,1432.587c-22.959,0-42.564-17.484-44.75-40.802c-2.321-24.744,15.857-46.685,40.602-49.005
				l598.251-56.102c24.742-2.315,46.685,15.858,49.005,40.603s-15.858,46.685-40.603,49.005l-598.25,56.102
				C98.434,1432.521,97.012,1432.587,95.606,1432.587z"/>
                    </g>
                    <g>
                        <path fill="#3E7F00" d="M121.882,1559.458c-10.115,0-20.288-3.392-28.685-10.35c-19.138-15.855-21.798-44.224-5.942-63.361
				l87.475-105.578l-105.579-87.474c-19.138-15.856-21.798-44.225-5.942-63.362c15.856-19.137,44.226-21.799,63.361-5.941
				l140.231,116.184c9.19,7.614,14.979,18.567,16.094,30.45c1.114,11.882-2.538,23.721-10.152,32.911l-116.185,140.23
				C147.66,1553.907,134.819,1559.458,121.882,1559.458z"/>
                    </g>
                    <g>
                        <path fill="#3E7F00" d="M217.856,1649.664c-10.115,0-20.288-3.392-28.685-10.35c-19.138-15.855-21.798-44.224-5.942-63.361
				l176.595-213.143l-213.141-176.594c-19.138-15.855-21.798-44.224-5.942-63.361c15.856-19.137,44.225-21.799,63.362-5.941
				l247.793,205.304c9.19,7.614,14.979,18.567,16.094,30.45c1.114,11.883-2.538,23.721-10.152,32.911l-205.305,247.794
				C243.634,1644.113,230.793,1649.664,217.856,1649.664z"/>
                    </g>
                    <g>
                        <path fill="#3E7F00" d="M311.299,1747.301c-10.115,0-20.288-3.392-28.685-10.35c-19.138-15.856-21.798-44.224-5.941-63.361
				l272.178-328.504L220.345,1072.91c-19.138-15.855-21.798-44.224-5.942-63.361c15.857-19.137,44.226-21.799,63.362-5.941
				l363.157,300.886c9.19,7.614,14.979,18.567,16.094,30.45s-2.538,23.721-10.152,32.911L345.975,1731.01
				C337.077,1741.75,324.236,1747.301,311.299,1747.301z"/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}