import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#498739" d="M270.901,687.847c18.615,49.825,67.754,69.125,125.992,79.327c5.224,2.482-3.591,22.482,4.665,32.665
		c16.199,21.347,72.781,10.607,102.66,23.331c67.584,28.778,70.809,130.2,97.994,214.65c-13.259,0.813,5.64-30.527-14-23.332
		c-14.894-0.318-12.107,29.337-9.332,32.665c22.691-2.862,23.972-2.862,46.664,0c-1.848-12.392-20.132-26.55,4.665-27.999
		c-30.109-11.856-31.77-56.341-37.329-79.326C558.349,797.09,477.655,658.608,270.901,687.847z"/>
                <path fill="#498739" d="M485.551,636.517c-32.623,14.579-24.761,70.441,4.667,83.994c41.418,21.42,109.211,102.003,121.325,223.983
		c0.558,7.759-2.549,42.261,4.666,27.997c0.869-81.311-18.198-189.478-9.332-284.646c2.959-31.771,28.179-69.284,0-88.659
		c-19.694-14.334-21.516,38.479-27.999,18.666c-28.998-96.611-40.911-207.969-102.659-251.981
		c-62.834-44.788-159.484,52.342-121.013,155.24C356.917,525.689,391.404,597.601,485.551,636.517z"/>
                <path fill="#498739" d="M648.872,949.159c5.085,18.479-9.969,27.758-9.332,55.997c27.332-41.107,32.671-104.208,65.328-139.989
		c57.899-4.165,188.274,7.435,79.327-102.659c-55.87-25.585,77.077,2.645,81.63-40.591c2.469-24.244-31.79-38.804-34.967-57.401
		c62.736-29.419,117.897-44.341,135.323-93.327c46.597-130.998-253.387-198.305-251.981-41.995
		c0.321,35.661,33.679,53.955,23.333,97.992c-8.537,36.339-49.274,16.855-93.326,46.662c-24.124,19.366-18.665,43.952-4.667,51.329
		c10.657,11.12,81.501,11.746,41.996,23.332c-34.469,13.749-69.797,26.641-65.328,79.327c3.875,49.319,29.454,17.21,37.331,32.665
		c-6.923,14.853-0.028,43.523-9.332,55.994c0.7,8.631,15.8,2.865,18.664,9.334c-15.799,1.309-18.751,15.468-23.331,27.997
		C641.189,960.364,647.754,954.229,648.872,949.159z"/>
                <path fill="#498739" d="M700.202,1023.821c-24.419,0.25-51.212,13.287-60.662-9.333c-4.744,3.031-4.825,10.729-13.998,9.333
		c16.516,43.414,79.741,6.951,111.991,18.665C727.593,1023.537,699.019,1048.962,700.202,1023.821z"/>
            </g>
            <path fill="#BC2F1D" d="M847.372,1191.644c-1.503-16.017-4.502-31.423-8.99-45.868c-20.795-96.959-110.221-169.865-217.384-169.865
	c-101.521,0-187.124,65.431-213.542,154.754c-33.595,86.7-1.197,191.405,47.14,242.26c46.486,48.913,125.236,66.313,157.814,126.495
	c-8.689,56.057-52.971,85.01-43.824,135.898c6.992-38.184,26.279-64.071,47.141-88.386c-0.466,106.525,1.614,210.513,11.783,306.405
	c38.058-74.614-12.188-178.04,4.735-221.437c24.991,37.486,29.613,70.501,49.227,106.211c-3.61-53.352-32.125-103.025-53.962-138.15
	c-1.94-21.737,0.677-58.239,0.823-87.101c20.583-61.775,76.451-78.467,122.354-109.723
	C798.245,1370.755,856.607,1289.921,847.372,1191.644z"/>
        </SvgIcon>
    )
}