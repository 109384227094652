import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBC02D" d="M452.447,483.235c4.888,0,9.781,0,14.669,0
		c35.872-4.085,17.517,46.05,22.003,73.348c120.216,26.696,125.532,172.376,183.368,278.718
		c43.015,79.086,91.734,147.798,102.687,227.376c25.115,182.484-83.58,359.251-212.708,410.744
		c-187.781,74.883-401.897-111.967-403.409-344.73c-0.96-147.971,100.966-242.637,146.694-359.4
		c32.219-82.271,26.34-193.736,146.694-205.374C452.447,537.024,452.447,510.129,452.447,483.235z"/>
                <g>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#F6961B" d="M714.725,1684.889
				c-62.705-17.181-118.334-66.489-152.622-135.284c-17.212-34.533-28.135-71.806-32.461-110.781
				c-4.55-40.979-1.513-81.773,9.028-121.246c21.401-80.142,73.661-130.586,128.988-183.993
				c28.324-27.342,57.613-55.614,81.581-87.758c11.685-15.671,21.744-33.048,31.47-49.852c15.583-26.917,30.3-52.342,50.08-69.069
				c20.706-17.515,45.108-23.124,76.79-17.651l17.107,2.954l1.988-7.25l8.947,4.729c59.833,31.604,64.834,97.193,70.628,173.14
				c2.55,33.421,5.185,67.978,12.958,101.075c3.95,16.821,8.242,33.334,12.392,49.301c15.149,58.297,29.459,113.361,22.467,166.564
				c-9.626,73.246-44.382,144.083-97.868,199.461c-49.771,51.529-111.307,84.797-168.831,91.266
				C762.816,1693.256,738.375,1691.37,714.725,1684.889z"/>
                        <path fill="#E4ED6E" d="M938.554,933.956c15.783,11.507,26.428,27.272,33.761,49.57c9.353,28.44,12.031,63.566,14.87,100.754
				c2.603,34.146,5.296,69.455,13.408,103.994c4.002,17.04,8.322,33.662,12.497,49.738c15.306,58.893,28.523,109.753,22.018,159.262
				c-9.095,69.214-42.005,136.218-92.664,188.669c-46.732,48.386-104.035,79.567-157.214,85.55
				c-22.13,2.489-44.15,0.792-65.452-5.045c-57.465-15.745-108.697-61.442-140.562-125.373
				c-16.207-32.516-26.492-67.627-30.57-104.361c-4.287-38.61-1.428-77.034,8.497-114.204
				c20.03-75.006,70.433-123.66,123.793-175.168c28.876-27.874,58.733-56.695,83.632-90.086
				c12.347-16.562,22.688-34.426,32.69-51.702c14.725-25.438,28.635-49.466,45.878-64.05c7.581-6.414,15.476-10.646,24.132-12.938
				c10.564-2.798,22.685-2.952,37.057-0.47l34.216,5.91L938.554,933.956 M946.372,824.321c-1.072-0.294-2.235-0.54-3.482-0.734
				c-4.261-1.168-8.524-2.335-12.786-3.504c-6.423,23.442-12.846,46.887-19.27,70.329
				c-107.691-18.603-129.189,79.965-176.925,143.983c-67.749,90.861-179.209,149.036-213.714,278.248
				c-45.992,172.233,48.95,352.183,189.476,390.688c25.314,6.938,52.116,9.282,79.833,6.164
				c124.858-14.045,261.826-142.168,283.52-307.237c9.459-71.983-16.597-143.516-35.203-222.727
				c-25.016-106.512,5.146-234.77-93.268-286.753C947.06,868.873,973.013,831.615,946.372,824.321L946.372,824.321z"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#E4ED6E" d="M888.519,1402.144
			c-28.255,103.116-84.865,142.313-137.497,127.891c-52.634-14.421-81.356-76.997-53.103-180.114
			c28.255-103.116,97.733-189.279,150.365-174.858C900.918,1189.483,916.773,1299.029,888.519,1402.144z"/>
                    <path fill="#C2CC3A" d="M991.761,969.852c10.237,31.132,13.071,68.28,16.07,107.611c2.643,34.633,5.373,70.446,13.465,104.905
			c4.088,17.397,8.521,34.455,12.809,50.952c15.522,59.731,30.184,116.151,23.078,170.229
			c-9.814,74.678-45.265,146.915-99.822,203.402c-50.679,52.472-113.233,86.333-171.625,92.9
			c-24.798,2.789-49.48,0.884-73.367-5.661c-63.539-17.41-119.966-67.485-154.807-137.39
			c-17.537-35.184-28.664-73.161-33.074-112.877c-4.636-41.758-1.542-83.322,9.198-123.538
			c9.831-36.816,26.524-69.684,52.535-103.436c23.49-30.479,51.909-57.911,79.391-84.441c29.34-28.32,59.678-57.605,84.605-91.038
			c12.198-16.359,22.637-34.391,32.731-51.827c15.888-27.445,30.894-53.368,50.743-70.156c9.621-8.138,19.745-13.536,30.953-16.505
			c13.106-3.473,21.614-9.235,66.24,2.918C985.829,929.448,991.761,969.852,991.761,969.852z M950.229,810.475
			c-1.106-0.302-2.304-0.557-3.591-0.757c-4.396-1.205-8.796-2.409-13.191-3.614c-6.625,24.183-13.252,48.367-19.879,72.55
			c-111.091-19.188-133.268,82.489-182.511,148.53c-69.889,93.73-184.869,153.742-220.463,287.036
			c-47.444,177.672,50.495,363.301,195.459,403.022c26.114,7.155,53.762,9.576,82.354,6.36
			c128.802-14.487,270.096-146.659,292.473-316.939c9.759-74.256-17.121-148.048-36.313-229.762
			c-25.806-109.873,5.307-242.182-96.214-295.807C950.938,856.435,977.71,818.001,950.229,810.475L950.229,810.475z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#212121" d="M827.482,1203.254c8.924,4.13,8.096,13.817,16.068,18.494
			c11.217-5.9,18.916-29.329,2.426-34.563C841.846,1196.108,832.153,1195.279,827.482,1203.254z M823.262,1244.367
			c6.544-16.63,5.33-28.842-12.479-31.6C791.773,1226.433,797.075,1253.471,823.262,1244.367z M841.756,1228.299
			c-6.706,16.726,3.182,23.994,20.826,26.843c6.542-16.631,5.329-28.843-12.479-31.6
			C848.64,1227.437,846.999,1231.03,841.756,1228.299z M763.652,1256.218c13.664,19.009,40.704,13.707,31.601-12.479
			C779.154,1238.135,771.98,1248.185,763.652,1256.218z M813.117,1255.679c-6.705,16.725,3.182,23.992,20.825,26.841
			c2.394-8.737,4.788-17.472,7.183-26.211C829.847,1252.688,820.342,1252.186,813.117,1255.679z M858.99,1268.249
			c-6.706,16.725,3.182,23.993,20.827,26.841c6.541-16.632,5.328-28.842-12.478-31.599
			C865.872,1267.386,864.234,1270.979,858.99,1268.249z M784.479,1283.061c-2.754,8.94-0.858,15.232,1.166,21.454
			c16.726,6.704,23.994-3.182,26.842-20.826C801.209,1280.067,791.704,1279.565,784.479,1283.061z M824.965,1315.288
			c11.987,4.863,16.479-3.426,28.01,0.629C864.365,1283.365,809.46,1283.732,824.965,1315.288z M749.287,1308.643
			c3.652-13.001,20.164-3.435,20.289-22.622c-8.276-2.655-6.772-10.888-17.863-11.938
			C740.496,1279.979,732.795,1303.407,749.287,1308.643z M888.703,1339.798c6.54-16.633,5.329-28.845-12.478-31.6
			C857.214,1321.86,862.516,1348.9,888.703,1339.798z M791.57,1334.317c9.097,2.189,13.544,7.027,17.864,11.94
			c11.216-5.897,18.915-29.325,2.425-34.561C808.206,1324.698,791.693,1315.13,791.57,1334.317z M731.961,1346.166
			c13.08-6.941,19.82-34.735-4.129-36.356C710.604,1319.115,719.952,1337.744,731.961,1346.166z M758.174,1353.348
			c28.546,11.402,30.957-28.418,8.979-32.765C756.383,1326.888,752.123,1342.435,758.174,1353.348z M851.717,1371.935
			c6.54-16.633,5.33-28.844-12.479-31.6C820.227,1353.997,825.529,1381.037,851.717,1371.935z M863.656,1354.069
			c-6.706,16.727,3.182,23.992,20.825,26.842c6.542-16.631,5.331-28.844-12.478-31.597
			C870.539,1353.207,868.9,1356.802,863.656,1354.069z M784.387,1360.532c-2.754,8.942-0.856,15.232,1.167,21.453
			c16.726,6.707,23.992-3.181,26.841-20.825C801.117,1357.542,791.61,1357.036,784.387,1360.532z M703.323,1373.546
			c8.297,4.488,9.958,12.758,24.416,13.736c2.192-9.098,7.028-13.545,11.941-17.865c-8.294-4.488-9.961-12.757-24.417-13.736
			C713.074,1364.778,708.235,1369.226,703.323,1373.546z M830.798,1422.564c17.228-9.307,7.881-27.934-4.129-36.357
			C813.589,1393.149,806.849,1420.943,830.798,1422.564z M851.086,1399.943c-2.754,8.943-0.857,15.235,1.166,21.457
			c16.727,6.703,23.993-3.185,26.843-20.831C867.814,1396.952,858.312,1396.448,851.086,1399.943z M758.711,1402.813
			c4.488-8.294,12.758-9.961,13.737-24.416c-8.74-2.396-17.474-4.788-26.216-7.185
			C739.691,1387.846,740.904,1400.056,758.711,1402.813z M787.255,1452.905c11.28,3.619,20.782,4.122,28.013,0.632
			c13.093-27.74-37.025-19.454-13.646-53.058c-11.277-3.619-20.783-4.123-28.009-0.627
			C760.521,1427.59,810.637,1419.305,787.255,1452.905z M731.867,1423.638c6.709-16.724-3.182-23.992-20.824-26.841
			C699.261,1418.78,716.078,1433.149,731.867,1423.638z M833.761,1437.468c-1.198,4.367-2.396,8.737-3.592,13.105
			c3.894,1.467,7.491,3.106,4.757,8.35c4.367,1.197,8.738,2.395,13.106,3.592c1.467-3.896,3.104-7.487,8.351-4.76
			c1.195-4.367,2.394-8.738,3.591-13.105c-3.896-1.468-7.493-3.105-4.759-8.349c-4.368-1.196-8.739-2.394-13.106-3.592
			C840.642,1436.604,839.005,1440.196,833.761,1437.468z M741.385,1440.337c11.28,3.62,20.78,4.121,28.008,0.63
			c6.707-16.728-3.181-23.994-20.825-26.842C746.173,1422.862,743.78,1431.597,741.385,1440.337z M707.989,1459.367
			c8.738,2.395,17.475,4.789,26.214,7.183c6.541-16.633,5.328-28.844-12.478-31.6
			C717.237,1443.245,708.968,1444.909,707.989,1459.367z M759.249,1452.276c-6.706,16.727,3.181,23.994,20.824,26.843
			C789.207,1452.485,779.519,1450.627,759.249,1452.276z M817.6,1496.447c13.078-6.944,19.82-34.737-4.128-36.358
			C796.242,1469.396,805.59,1488.022,817.6,1496.447z M725.226,1499.315c8.739,2.396,17.474,4.788,26.211,7.184
			c6.544-16.631,5.33-28.844-12.478-31.6C734.472,1483.194,726.202,1484.858,725.226,1499.315z M771.096,1511.884
			c31.265,17.726,39.287-23.721,7.183-26.212C775.884,1494.41,773.489,1503.147,771.096,1511.884z"/>
                </g>
            </g>
        </SvgIcon>
    )
}