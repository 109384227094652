import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FBC86C" d="M654.755,1101.268c-16.116,11.487-39.047,23.17-49.248,44.324
			c0.688,0.029,1.366-0.027,2.049-0.053c-17.515,5.892-29.497,34.361-39.596,52.717c-21.127,38.396-36.14,72.327-66.111,105.113
			c-96.512,105.57-230.737,119.93-359.18,55.047c6.663,6.183,19.864,11.27,22.365,18.15c-8.438,8.58-15.037,28.129-24.828,28.637
			c-30.13-15.219-19.011-54.097-27.432-79.133c-6.435-19.13-22.479-34.279-30.75-52.812
			c-32.863-73.625-38.528-169.05,41.738-265.908c51.384-62.005,136.92-82.773,190.767-137.599
			c77.146-78.554,73.545-178.8,149.084-271.02c51.645-63.052,120.11-93.256,174.719-102.708
			c76.975-13.324,161.1,4.902,247.353,77.051c9.812,8.21,38.369,39.167,39.262,40.172c0.142,0.161,0.286,0.314,0.431,0.474
			c75.637,94.698,93.959,223.938-1.754,339.435c-70.521,85.094-166.196,101.483-245.07,136.396"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#BB5549" d="M931.219,601.903C846.018,487.139,703.601,468.558,615.433,491.7
			c-64.892,17.035-130.319,62.756-176.993,127.564c-46.649,64.775-55.246,146.176-105.484,216.051
			c-65.496,91.099-160.494,89.406-223.314,173.97c-6.607,9.265-13.21,18.529-19.816,27.795
			c-60.337,105.434-46.514,198.44,16.548,283.188c4.203,32.812-6.432,74.901,36.847,96.877c12.474-4.994,21.659-29.608,33.59-37.833
			c121.775,62.899,255.943,31.646,348.504-80.31c41.767-50.519,51.789-103.576,96.56-154.012c-2.257-0.738-4.431-1.087-6.537-1.119
			c15.553-4.362,29.134-20.502,43.89-29.617c-2.767-4.26-0.79-9.313-3.562-13.573c-16.114,11.485-39.045,23.171-49.248,44.324
			c0.69,0.027,1.368-0.027,2.051-0.053c-17.515,5.89-29.497,34.362-39.597,52.717c-21.126,38.396-36.139,72.327-66.11,105.113
			c-96.511,105.57-230.735,119.93-359.179,55.047c6.663,6.184,19.864,11.27,22.365,18.15c-8.438,8.58-15.037,28.129-24.828,28.635
			c-30.131-15.217-19.011-54.097-27.432-79.131c-6.436-19.132-22.479-34.279-30.75-52.811
			c-32.862-73.626-38.528-169.052,41.738-265.909c51.384-62.005,136.92-82.772,190.767-137.599
			c77.147-78.554,73.545-178.8,149.086-271.021c51.643-63.051,120.11-93.256,174.716-102.708
			c76.976-13.324,161.098,4.903,247.351,77.053c9.814,8.21,38.372,39.166,39.264,40.17c0.143,0.163,0.284,0.315,0.429,0.474
			c75.642,94.697,93.959,223.938-1.752,339.435c-70.518,85.094-166.193,101.483-245.07,136.396
			c-9.311,4.122-28.842,9.188-18.773,26.333c93.503-55.466,216.583-54.714,300.578-189.496
			c52.195-83.757,51.227-177.156,15.853-251.265c-6.078-12.734-17.996-37.187-30.822-53.254c-2.607-4.496-6.837-10.223-12.05-16.085
			C933.235,604.055,932.223,602.965,931.219,601.903z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#DBA349" d="M925.377,613.089c-0.145-0.16-0.289-0.312-0.431-0.474
			c-0.893-1.004-29.45-31.962-39.262-40.171c-8.416-7.041-16.811-13.559-25.181-19.593c74.164,100.02,89.249,232.963-9.551,352.187
			c-75.244,90.794-177.328,108.28-261.483,145.531l-25.393,12.502c-17.197,12.255-41.664,24.722-52.547,47.291
			c0.734,0.032,1.457-0.029,2.186-0.055c-18.688,6.285-31.473,36.663-42.248,56.246c-22.543,40.969-38.561,77.172-70.539,112.154
			c-59.128,64.676-131.525,97.256-208.402,100.18c112.557,38.553,225.248,15.813,309.32-76.15
			c29.97-32.787,44.983-66.717,66.111-105.114c10.098-18.354,22.081-46.824,39.595-52.716c-0.683,0.024-1.361,0.082-2.048,0.053
			c10.2-21.152,33.131-32.836,49.248-44.323l23.799-11.717c78.874-34.913,174.55-51.301,245.07-136.395
			C1019.336,837.025,1001.014,707.788,925.377,613.089z"/>
                </g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FBC86C" d="M609.77,1576.598c-18.707-6.455-41.534-18.346-64.678-14.373
			c0.376,0.578,0.815,1.1,1.231,1.641c-14.963-10.843-45.102-4.072-65.91-1.641c-43.53,5.087-79.874,12.559-123.968,7.188
			C214.458,1552.109,124.85,1451.15,103.12,1308.9c-1.167,9.015,2.354,22.716-1.796,28.746c-11.884-1.891-31.634,4.086-37.729-3.593
			c-5.099-33.37,33.013-46.885,48.509-68.272c11.843-16.346,14.864-38.202,25.153-55.695
			c40.873-69.497,115.286-129.506,240.748-120.375c80.316,5.846,146.88,63.44,222.781,75.459
			c108.746,17.219,188.283-43.908,307.224-35.935c81.318,5.453,145.657,43.668,185.053,82.646
			c55.535,54.941,89.528,134.023,80.848,246.138c-0.986,12.758-9.619,53.979-9.919,55.288c-0.047,0.21-0.089,0.417-0.134,0.626
			c-33.202,116.563-127.803,206.505-277.407,195.613c-110.226-8.021-179.109-76.414-253.326-120.373"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#BB5549" d="M1176.868,1511.832
			c43.992-135.996-23.551-262.748-93.577-321.104c-51.541-42.953-126.751-69.686-206.613-70.07
			c-79.823-0.383-151.096,39.87-237.154,39.526c-112.199-0.449-165.967-78.783-271.291-80.848c-11.38,0-22.756,0-34.136,0
			c-120.876,12.075-188.583,77.319-220.985,177.866c-24.277,22.47-64.721,38.241-57.493,86.238
			c11.308,7.259,36.682,0.448,50.306,5.39c19.474,135.67,122.806,226.775,267.698,237.155
			c65.38,4.683,114.401-17.956,181.459-10.778c-0.71-2.266-1.689-4.239-2.885-5.975c12.582,10.134,33.607,11.823,49.597,18.55
			c1.86-4.728,7.124-6.052,8.984-10.78c-18.708-6.454-41.534-18.345-64.68-14.373c0.379,0.579,0.818,1.099,1.232,1.64
			c-14.961-10.841-45.102-4.071-65.91-1.64c-43.53,5.086-79.873,12.559-123.967,7.188
			c-141.986-17.304-231.595-118.263-253.325-260.512c-1.166,9.014,2.354,22.717-1.796,28.746
			c-11.885-1.89-31.634,4.085-37.729-3.593c-5.099-33.37,33.013-46.884,48.509-68.272c11.842-16.347,14.864-38.202,25.152-55.695
			c40.873-69.498,115.286-129.506,240.749-120.374c80.316,5.845,146.879,63.44,222.781,75.458
			c108.745,17.219,188.282-43.906,307.224-35.933c81.318,5.452,145.657,43.666,185.053,82.646
			c55.534,54.942,89.527,134.023,80.85,246.138c-0.989,12.757-9.62,53.979-9.919,55.288c-0.05,0.211-0.09,0.416-0.137,0.625
			c-33.201,116.563-127.802,206.505-277.406,195.615c-110.226-8.023-179.109-76.415-253.325-120.374
			c-8.759-5.188-24.222-18.153-32.339,0c99.44,43.937,170.276,144.593,328.783,134.747c98.5-6.12,173.989-61.126,213.799-132.95
			c6.841-12.342,19.833-36.241,25.47-56.012c2.148-4.733,4.356-11.502,6.104-19.149
			C1176.288,1514.725,1176.59,1513.268,1176.868,1511.832z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#A54840" d="M343.134,1171.279c90.515-7.751,174.997,10.472,214.796,55.489
			c39.562,44.745,33.904,157.138,0,211.216c-68.424,109.132-262.718,68.539-347.254-10.74
			c-30.663-28.757-74.656-78.219-57.279-139.617C172.121,1221.471,250.316,1179.227,343.134,1171.279z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#A54840" d="M1081.959,1325.713
			c-40.707-41.701-100.202-73.938-161.098-96.659c-17.176-6.41-53.093-20.622-68.018-17.9
			c-1.013,0.186-155.376-0.341-195.938,48.188c0.526,0.495,0.483,0.562,0,0c-68.625,61.907-22.661,173.729-22.437,173.77
			c56.136,95.909,143.044,180.365,282.814,189.736c77.635,5.206,140.661-22.149,177.206-64.438
			c20.628-23.87,41.787-62.834,42.961-105.608C1138.873,1400.848,1110.843,1355.297,1081.959,1325.713z"/>

                    <ellipse transform="matrix(0.9461 -0.324 0.324 0.9461 -395.5892 155.3746)" fill="#C66A66" cx="268.838" cy="1265.749" rx="49.761" ry="20.095"/>

                    <ellipse transform="matrix(0.9461 -0.324 0.324 0.9461 -380.4203 307.6213)" fill="#C66A66" cx="733.691" cy="1296.354" rx="59.63" ry="24.081"/>
                </g>
            </g>
        </SvgIcon>
    )
}