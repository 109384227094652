import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#975219" d="M352.564,736.376c-17.141,4.031,11.206-29.774,29.565-25.87
			c18.5-54.398-13.476-105.303-59.13-118.26c39.847-99.846,127.926,10.149,107.173,103.478c10.145,5.868,25.31,11.004,34.513,17.817
			C447.083,737.932,370.089,736.033,352.564,736.376z"/>
                    <path fill="#FF5F3D" d="M408.328,727.708c0,0-38.806,18.478-174.926-4.923c-87.951-15.118-236.52,98.551-194.635,396.662
			s450.865,453.326,724.34,231.59c273.476-221.737,135.506-601.155-22.174-682.458C583.254,587.274,573.398,675.969,408.328,727.708
			z"/>
                </g>
                <g>
                    <path fill="#FB613B" d="M860.997,975.722c0,0-40.13-0.646-144.286-76.713c-67.295-49.151-238.747-15.721-327.433,251.027
			c-88.684,266.756,189.012,566.328,509.799,494.347c320.79-71.989,362.844-446.636,264.687-580.086
			C1065.605,930.847,1020.553,1000.952,860.997,975.722z"/>
                    <path fill="#F8C866" d="M631.297,923.575c0,0-165.071,7.391-197.099,312.896c-32.028,305.505,266.085,372.02,352.315,362.167
			c86.23-9.853,408.98-51.739,364.634-433.618c0,0-20.605-181.446-169.998-165.072
			C801.295,1019.661,773.378,886.691,631.297,923.575z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#895C21" d="M906.272,1282.978
			c-18.796,90.683-118.032,183.607-179.237,170.922s-79.773-143.064-60.978-233.748c18.795-90.683,98.017-132.998,159.222-120.313
			S925.067,1192.294,906.272,1282.978z"/>
                </g>
            </g>
        </SvgIcon>
    )
}