import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#F18F2C" d="M865.699,896.771c-64.074-84.108-191.348-169.659-286.56-169.659c-0.037,0-0.072,0.003-0.11,0.003
		c-11.407-1.522-22.969-2.326-34.664-2.326c-115.317,0-254.09,71.143-319.47,188.613c-46.574,83.682-38.071,193.102-38.071,306.967
		c0,141.728,36.133,269.561,104.943,359.886c60.568,79.506,147.262,129.922,236.991,135.204
		c44.831,7.677,91.219,9.542,134.932,9.542c115.316,0,254.09-71.145,319.471-188.614c46.573-83.683,72.624-132.004,72.624-245.868
		C1055.785,1148.789,934.51,987.096,865.699,896.771z"/>
                <path fill="#799C22" d="M324.104,500.836c0,0,69.396,116.164,78.449,158.404c9.051,42.241,10.561,57.328,0,102.586
		c-10.561,45.259,10.559,34.699,25.646,36.207c15.087,1.508,34.698,12.068,34.698,12.068s16.595-34.697,27.155-34.697
		s16.596-6.036,16.596-6.036s-51.294-58.834-66.38-113.899c-15.085-55.064-33.19-139.546-43.75-147.089
		C385.958,500.836,346.734,460.104,324.104,500.836z"/>
            </g>
        </SvgIcon>
    )
}