import React from 'react';
import { SvgIcon } from '@mui/material';


export default function (props) {
    return (
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#D8AD73" d="M965.998,1349.246c-103.879,79.027-252.152,58.883-331.182-44.996c-5.002-6.576-9.604-13.332-13.816-20.236
		c7.625,18.371,17.693,36.072,30.3,52.644c79.027,103.878,227.301,124.022,331.178,44.996
		c60.641-46.133,92.746-115.861,93.244-186.585c0.301-42.756-13.137-43.188-18.59-2.507
		C1049.08,1252.646,1018.057,1309.643,965.998,1349.246z"/>
                <path fill="#D8AD73" d="M980.336,1105.693c56.184,28.846,89.184,93.441,60.336,149.627c-1.828,3.555-3.816,6.975-5.949,10.256
		c6.426-7.164,12.051-15.254,16.654-24.219c28.848-56.18,6.688-125.113-49.496-153.961c-32.797-16.842-75.748-28.374-106.725-13.827
		c-18.727,8.795-16.111,14.75,2.787,8.638C925.852,1073.18,952.18,1091.235,980.336,1105.693z"/>
                <path fill="#D8AD73" d="M786.283,671.655c-159.316-87.128-378.639-58.439-455.258,81.655c-4.848,8.871-9.119,17.915-12.833,27.102
		c5.119-24.017,13.674-47.489,25.896-69.839c76.615-140.096,295.937-192.628,455.253-105.5
		c93.002,50.864,154.557,134.805,174.441,223.742c11.932,53.349-10.266,55.248-28.318,5.359
		C918.447,759.519,866.121,715.32,786.283,671.655z"/>
                <path fill="#D8AD73" d="M587.564,807.777c-132.958-94.752-295.719,35.842-287.242,20.338
		c53.141-97.171,185.797-126.951,296.299-66.519c64.507,35.278,107.201,93.5,120.994,155.188c8.34,37.296-3.947,38.776-16.635,3.718
		C682.242,868.722,638.965,844.407,587.564,807.777z"/>
                <path fill="#D8AD73" d="M95.842,1065.589c35.436,120.862,196.673,233.418,321.185,196.913c7.882-2.314-12.497,10.203-32.362,16.027
		c-124.512,36.508-299.827-63.65-335.262-184.513c-20.687-70.552-4.689-143.391,36.986-199.063
		c25.193-33.661,36.115-26.368,16.245,8.587C73.285,955.169,78.088,1005.022,95.842,1065.589z"/>
                <path fill="#D8AD73" d="M1155.744,1045.625c-3.381-102.989-70.857-178.592-181.549-174.962
		c-7.008,0.232-5.377-23.106,12.285-23.688c110.693-3.633,210.342,82.569,213.721,185.558
		c1.973,60.123-26.852,114.602-73.213,150.261c-28.027,21.557-35.488,13.557-11.582-10.109
		C1150.719,1137.727,1157.436,1097.239,1155.744,1045.625z"/>
                <path fill="#49270E" d="M646.832,580.613c82.621,0,160.199,29.122,218.441,82c57.656,52.347,89.41,91.425,89.41,165.144
		l-0.006,0.327c-0.006,0.324-0.012,0.647-0.014,0.972l-0.1,21.607l21.607,0.171c58.459,0.466,113.238,21.394,154.25,58.931
		c40.457,37.031,62.738,85.986,62.738,137.846c0,32.191-19.215,54.199-35.734,82.512c-15.918,27.283-60.795,29.506-88.766,47.447
		l-10.229,6.561l0.26,12.15c0.027,1.285,0.055,2.568,0.055,3.859c0,108.518-98.229,196.801-218.965,196.801
		c-65.725,0-136.262-10.527-235.923-50.061c-93.015-36.896-180.871-97.762-236.327-97.762
		c-175.727,0-318.691-82.111-318.691-215.016c0-48.747,51.268-119.636,87.749-159.83c36.589-40.315,177.767-17.997,173.431-59.399
		c-4.336-41.402,60.021-108.653,60.021-108.653c24.873-45.91,63.303-54.416,111.136-82.018
		C530.596,595.686,587.878,580.613,646.832,580.613 M646.832,528.582c-129.863,0-242.164,68.21-295.854,167.313
		c-44.766,20.269-81.595,52.821-105.128,92.805C117.971,826.497,27.161,922.108,27.161,1034.103
		c0,145.088,152.389,262.711,340.371,262.711c108.186,0,371.865,152.158,504.77,152.158s240.645-97.818,240.645-218.48
		c0-1.445-0.029-2.883-0.061-4.322c61.676-39.56,101.955-104.787,101.955-178.559c0-120.011-106.584-217.407-238.496-218.456
		c0.002-0.466,0.02-0.928,0.02-1.394C976.363,662.529,828.826,528.582,646.832,528.582L646.832,528.582z"/>
                <path fill="#49270E" d="M482.088,748.796c130.522,0,236.332,105.809,236.332,236.331c0,8.263-0.428,16.425-1.256,24.47
		c5.055-19.234,7.76-39.421,7.76-60.242c0-130.521-105.81-236.331-236.332-236.331c-76.194,0-143.964,36.059-187.181,92.041
		c-26.128,33.846-15.694,42.326,13.277,13.25C357.476,775.373,416.677,748.796,482.088,748.796z"/>
                <path fill="#49270E" d="M1062.887,1224.201c-0.154-83.807-68.215-151.619-152.021-151.466c-5.305,0.011-10.545,0.294-15.709,0.834
		c12.344-3.269,25.301-5.026,38.672-5.051c83.805-0.155,151.863,67.658,152.018,151.463c0.09,48.924-22.982,92.48-58.877,120.293
		c-21.701,16.816-27.158,10.127-8.523-8.509C1045.969,1304.242,1062.963,1266.199,1062.887,1224.201z"/>
                <path fill="#C6802C" d="M229.736,1673.998c-4.558,0-8.929-0.57-12.99-1.699c-22.518-6.254-35.831-15.439-38.499-26.559
		c-3.341-13.92,10.029-28.842,13.756-32.664c142.898-33.789,199.036-120.271,240.061-183.479
		c4.603-7.092,8.952-13.791,13.185-20.029c13.618-20.068,18.163-39.277,13.51-57.096c-7.22-27.642-33.949-41.283-35.083-41.85
		c-98.125-47.973-107.753-93.049-109.743-142.789c-1.312-32.802-17.457-58.525-31.031-74.531
		c28.846,13.872,68.66,42.949,76.637,90.813c11.157,66.944,73.901,100.104,76.567,101.481l9.336,4.83l-1.481-10.408
		c-0.106-0.741-10.259-74.598,14.515-117.953c7.701-13.479,15.392-18.809,22.83-23.967c19.571-13.57,30.485-25.223,27.211-106.85
		c-1.222-30.456-0.275-51.072,1.597-65.024c0.083,9.498,1.2,20.714,3.35,33.614c10.623,63.741,8.622,134.816,8.6,135.528
		c-2.51,90.359,16.876,129.799,33.581,146.973c14.139,14.536,27.636,14.703,28.204,14.703c24.079,0,65.634-16.389,105.868-41.754
		c20.889-13.169,70.434-47.476,79.318-81.536c3.143-12.049,5.275-21.93,7.156-30.649c5.879-27.242,9.117-42.249,33.199-75.966
		c12.207-17.088,16.701-32.924,13.361-47.073c-0.697-2.949-1.693-5.627-2.871-8.047c2.205,1.759,4.193,3.91,5.688,6.538
		c4.939,8.691,3.645,20.996-3.854,36.578c-25.514,53.031-5.424,107.059-2.514,114.297c8.762,73.307-11.506,128.672-60.25,164.559
		c-35.908,26.438-78.852,36.303-108.551,39.922c-1.547,0.189-3.18,0.285-4.85,0.285c-9.607,0-20.063-3.1-31.131-6.382
		c-12.996-3.853-26.436-7.835-41.036-7.835c-32.073,0-59.154,20.1-85.228,63.256
		C425.249,1527.359,303.482,1673.998,229.736,1673.998z"/>
                <path d="M514.487,1055.813c3.177,43.237-8.784,106.186-8.8,106.705c-2.057,74.051,5.222,107.625,30.578,133.695
		c15.451,15.885,45.927,6.25,47.585,6.25c25.029,0,71.804,9.557,112.845-16.316c21.434-13.512,70.842-54.852,80.215-90.783
		c3.172-12.16,2.689-42.344,4.582-51.117c5.32-24.658,8.256-38.266,25.869-64.652c-10.352,43.755,3.316,82.88,6.541,91.167
		c8.307,70.885-11.258,124.33-58.162,158.862c-34.963,25.741-76.938,35.365-105.994,38.907c-1.33,0.16-2.74,0.244-4.193,0.244
		c-8.82,0-18.91-2.992-29.59-6.158c-12.745-3.778-29.623-42.506-45.009-42.506c-34.159,0-60.282,55.379-87.436,100.32
		c-33.717,55.809-79.505,116.201-122.485,161.555c-52.917,55.84-99.545,86.592-131.296,86.592c-4.068,0-7.951-0.504-11.539-1.5
		c-20.181-5.607-32.493-13.617-34.669-22.559c-2.331-9.578,6.104-21.07,11.228-26.527c143.941-34.582,184.96-75.645,224.008-135.803
		c4.588-7.07,41.757-46.088,45.959-52.279c14.534-21.42,4.347-59.41-0.72-78.807c-7.833-29.994-36.678-44.715-37.988-45.371
		c-43.314-21.176-60.415-59.488-76.194-83.699c-14.165-21.738-29.403-27.721-30.47-54.418c-0.904-22.584-8.522-41.947-17.663-57.305
		c3.364,2.27,6.75,4.736,10.101,7.408c23.869,19.035,38.137,41.674,42.406,67.289c11.599,69.591,76.66,103.973,79.424,105.404
		l18.686,9.665l-2.976-20.827c-0.103-0.721-4.163-42.074,19.731-83.887c7.092-12.412,8.076-47.774,15.336-52.809
		C502.709,1129.855,514.559,1118.139,514.487,1055.813 M521.925,932.012c-8.227,0-22.317,12.292-18.822,99.456
		c4.869,121.406-23.315,82.382-49.331,127.91s-15.176,121.406-15.176,121.406s-62.871-32.52-73.711-97.559
		s-78.047-97.558-104.063-104.062c0,0,45.527,34.687,47.695,88.886s15.176,99.727,112.734,147.422c0,0,60.703,30.352,19.512,91.055
		s-93.223,164.766-251.484,201.621c0,0-52.031,47.695,26.016,69.375c4.615,1.283,9.442,1.896,14.44,1.896
		c79.51,0,203.908-155.473,263.06-253.381c29.114-48.188,55.909-60.639,80.589-60.639c26.377,0,50.345,14.217,72.167,14.217
		c1.852,0,3.684-0.103,5.506-0.324c43.359-5.285,195.117-33.469,173.438-211.242c0,0-23.971-56.367,2.105-110.566
		c26.078-54.199-25.953-58.535-25.953-58.535s36.855,19.512,4.336,65.039c-32.52,45.527-28.184,58.535-41.191,108.398
		s-132.246,119.238-179.942,119.238c0,0-60.703,0-56.367-156.094c0,0,2.168-71.542-8.672-136.582s8.672-65.039,8.672-65.039
		S525.157,932.012,521.925,932.012L521.925,932.012z"/>
            </g>
        </SvgIcon>
    )
}