import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#98BF0E" d="M545.772,551.964c0,0-120.152,73.26-127.835,185.278c-7.679,112.017,46.381,406.526,483.844,773.119
		c0,0,317.222,222.26,3.15,214.438c-314.072-7.82-604.509-311.293-683.248-593.01c-78.743-281.712,60.608-317.952,133.597-427.82
		c72.989-109.87,37.842-174.63,105.896-197.388S600.252,451.694,545.772,551.964z"/>
                <circle fillRule="evenodd" clipRule="evenodd" fill="#EEF4CB" cx="335.11" cy="1017.506" r="67.053"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#EEF4CB" d="M408.164,1267.475c-35.751,0.527-65.153-28.027-65.678-63.773
		c-0.525-35.748,28.026-65.152,63.777-65.678c35.745-0.525,65.15,28.027,65.675,63.777
		C472.462,1237.547,443.909,1266.951,408.164,1267.475z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#EEF4CB" d="M523.617,1439.947c-35.751,0.523-65.153-28.029-65.678-63.775
		c-0.525-35.75,28.025-65.152,63.777-65.676c35.745-0.527,65.149,28.027,65.675,63.773
		C587.917,1410.016,559.362,1439.422,523.617,1439.947z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#EEF4CB" d="M662.45,1565.105c-35.118,0.516-64.001-27.535-64.517-62.65
		c-0.517-35.119,27.53-64.004,62.651-64.52c35.117-0.516,64.004,27.531,64.519,62.648
		C725.619,1535.705,697.57,1564.59,662.45,1565.105z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#EEF4CB" d="M806.878,1645.535c-28.115,0.414-51.244-22.047-51.655-50.164
		c-0.415-28.121,22.043-51.248,50.161-51.66c28.119-0.412,51.248,22.045,51.661,50.164
		C857.457,1621.99,834.998,1645.123,806.878,1645.535z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#77892C" d="M792.949,1645.873c8.075-22.857,35.414-26.105,46.622-45.777
		c4.611-38.869-48.367-21.188-45.67-58.172C879.775,1525.586,885.849,1665.539,792.949,1645.873z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#77892C" d="M666.643,1563.863c11.815-22.936,51.232-17.762,46.723-57.326
		c-5.323-46.934-47.534-70.303-91.975-47.043C688.321,1343.416,812.847,1563.93,666.643,1563.863z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#77892C" d="M392.082,1272.59c62.129-13.455,108.232-129.967-10.496-115.602
		C473.445,1061.308,542.712,1303.16,392.082,1272.59z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#77892C" d="M564.588,1355.018c-14.932-30.57-37.595-35.754-69.084-23.729
		c11.129-44.926,72.446-13.143,92.398,0.842c8.009,46.045,8.006,46.508-0.845,92.398c-84.523,39.227-129.939,3.578-22.995-11.762
		C564.239,1393.521,564.411,1374.266,564.588,1355.018z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#77892C" d="M314.082,960.008c112.873-68.184,146.916,156.795,10.386,127.153
		c-5.017-10.331-9.957-20.751-22.887-23.31C372.711,1101.936,450.907,949.772,314.082,960.008z"/>
            </g>
        </SvgIcon>
    )
}