import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#498739" d="M586.203,1122.373c-3.479,33.324,2.568,96.104-37.48,118.424c35.094-2.635,12.085,20.69,11.824,38.439
		c31.327-8.294,33.125-8.534,65.528-8.789c3.271-5.196,1.598-47.364-19.256-44.112c-28.935-6.405,3.506,34.047-15.266,35.401
		c22.271-123.71-4.412-161.802,85.072-214.942c39.562-23.494,121.04-19.07,139.768-52.097c9.676-15.854-6.469-42.279,0.398-46.749
		c79.858-25.294,145.229-61.65,161.985-135.123C682.936,810.706,607.812,915.429,586.203,1122.373z"/>
                <path fill="#498739" d="M778.683,686.74c34.646-151.741-119.368-269.933-199.168-195.206
		c-78.421,73.434-23.872,139.876-46.397,281.004c-5.373,29.044-17.877-44.777-42.833-20.939
		c-35.921,32.514,6.559,80.443,16.698,124.5c30.374,131.97,36.079,182.518,52.613,296.535c12.818,18.671,1.958-29.193,1.279-40.194
		c-5.961-173.572,61.95-194.562,116.078-232.441c38.771-24.573,39.291-104.499-9.266-118.828
		C792.563,708.792,777.143,693.49,778.683,686.74z"/>
                <path fill="#8C2C5E" d="M918.813,1344.753c-2.129-22.693-6.379-44.521-12.737-64.987
		c-29.463-137.374-168.915-135.479-320.747-135.479c-143.838,0-265.122-1.151-302.551,125.403
		c-47.598,122.84-1.696,163.896,66.789,235.949c65.863,69.301,177.438,93.954,223.595,179.221
		c-12.312,79.423,22.353,59.936,22.56,19.044c29.163-87.524,108.318-111.173,173.355-155.457
		C836.457,1502.565,931.897,1483.994,918.813,1344.753z"/>
                <path fill="#B24283" d="M373.306,1279.236c0,0-82.176,127.977,90.677,224.321C636.835,1599.901,356.3,1397.771,373.306,1279.236z"
                />
            </g>
        </SvgIcon>
    )
}