import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fill="#FDD835" d="M474.383,782.107c-243.307,0-440.549,197.242-440.549,440.552c0,243.308,197.24,440.55,440.553,440.55
			c243.306,0,440.549-197.242,440.549-440.55C914.936,979.35,717.694,782.107,474.383,782.107z"/>
                    <g>
                        <path fill="#03662F" d="M440.718,834.614c-80.99-157.798,45.465-248.333,46.617-249.23l8.451,10.868
				c0,0-87.581,88.788-17.267,225.79L440.718,834.614z"/>
                    </g>
                    <path fill="#03662F" d="M454.717,643.341c0,0,173.072-158.097,275.343-73.805c102.271,84.294,120.628,110.516,212.406,92.161
			c0,0-167.826,196.675-243.874,120.627S572.722,630.229,454.717,643.341z"/>
                </g>
                <g>
                    <path fill="#F2B297" d="M354.536,1283.627L354.536,1283.627c28.398,205.095,204.39,363.026,417.299,363.026
			c212.905,0,388.897-157.932,417.295-363.026l0,0H354.536z"/>
                    <path fill="none" d="M771.835,1608.141c191.8,0,351.248-140.838,380.572-324.514H391.261
			C420.586,1467.303,580.033,1608.141,771.835,1608.141z"/>
                    <path fill="#D8876A" d="M771.835,1608.141c-191.802,0-351.249-140.838-380.574-324.514h-55.758
			c29.693,214.449,213.711,379.582,436.332,379.582c222.616,0,406.635-165.133,436.331-379.582h-55.759
			C1123.083,1467.303,963.635,1608.141,771.835,1608.141z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#D05414" d="M755.637,1299.411c6.737,10.415-61.283,61.748-100.026,93.933
			c-35.475,29.471-119.906,102.614-130.462,83.095c-34.502-37.926-80.244-84.604-69.584-148.124
			C459.932,1311.997,748.896,1288.998,755.637,1299.411z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#D05414" d="M788.03,1299.411c-6.737,10.415,61.284,61.748,100.026,93.933
			c35.475,29.471,119.905,102.614,130.462,83.095c34.503-37.926,80.245-84.604,69.585-148.124
			C1083.737,1311.997,794.772,1288.998,788.03,1299.411z"/>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D05414" d="M786.457,1335.596c-9.853,2.112-0.267,70.684,3.502,111.43
				c3.449,37.311,9.935,127.834,27.211,122.687c41.611-1.966,94.705-2.504,125.071-45.12
				C949.109,1512.708,796.314,1333.486,786.457,1335.596z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D05414" d="M757.21,1335.596c-9.856-2.109-162.65,177.112-155.785,188.996
				c30.367,42.616,83.462,43.154,125.072,45.12c17.276,5.147,23.762-85.376,27.212-122.687
				C757.478,1406.279,767.063,1337.708,757.21,1335.596z"/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}