import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#E6CB88" d="M788.035,666.307c-230.787-32.582-290.52-135.757-298.666-81.455
		c-8.146,54.303-57.018,76.023-130.327,92.314s-293.235,133.782-192.775,593.63c0,0,260.654,60.72,545.743-113.05
		c285.091-173.769,320.387-222.641,320.387-222.641S1018.821,698.889,788.035,666.307z M394.34,762.266
		c0,0-97.745,58.805-96.387,158.408l-31.219-23.116c-1.604-65.793,32.384-104.317,61.181-125.047
		c30.396-21.882,61.202-28.566,62.498-28.84L394.34,762.266L394.34,762.266z"/>
                <path fill="#8C821F" d="M114.68,1190.328c0,0-106.532,50.947-70.594,108.606c35.938,57.66,198.206,48.872,198.206,48.872
		S98.39,1388.534,114.68,1190.328z"/>
                <path fill="#8C821F" d="M964.52,731.47c0,0,233.502,84.169,141.188-97.745l78.738-29.867l-24.437,147.056
		c0,0,26.102,23.773,14.935,64.726c-8.146,29.866-103.859,123.463-112.679,101.817C1052.737,894.071,1064.979,793.918,964.52,731.47
		z"/>
                <path fill="#C0BA4A" d="M44.086,1298.935c0,0,377.405,70.594,627.197-73.309c210.039-121,368.247-224.725,463.322-324.084
		c17.995-18.806,33.729-37.456,47.125-56.036c0,0,154.763,437.716-328.532,733.377C304.74,1809.382,44.086,1298.935,44.086,1298.935
		z"/>
                <path fill="#D3AF5E" d="M788.035,666.286c-92.762-13.096-185.385-60.019-183.831-50.042
		c20.884,133.946,5.104,292.134-52.93,450.052c-26.153,71.163-58.881,136.809-96.339,195.566
		c78.151-17.32,166.368-48.847,257.075-104.136c285.091-173.769,320.387-222.642,320.387-222.642S1018.821,698.867,788.035,666.286z
		"/>
            </g>
        </SvgIcon>
    )
}