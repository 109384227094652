import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#1A3F0B" d="M536.57,752.039c0,0,43.876,1.266,62.719,49.326s10.188-56.639,10.188-56.639l-60.362-34.496
		L536.57,752.039z"/>
                <g>
                    <path fill="#267500" d="M110.511,500.062c0,0,184.681,502.632,459.987,251.977L110.511,500.062z"/>
                    <path fill="#359704" d="M110.511,500.062c0,0,522.996-114.971,459.987,251.977L110.511,500.062z"/>
                </g>
                <g>
                    <path fill="#7B4200" d="M629.076,661.812v244.601c21.649,0,39.197-17.55,39.197-39.197V682.193
			C668.273,660.546,650.726,661.812,629.076,661.812z"/>
                    <path fill="#874708" d="M629.076,661.812v244.601c-21.65,0-39.198-17.55-39.198-39.197V682.193
			C589.878,660.546,607.426,661.812,629.076,661.812z"/>
                </g>
                <g>
                    <path fill="#FFA300" d="M629.076,849.775c0,0-42.331,2.354-95.642-28.221c-53.311-30.575-192.858-36.062-263.416,53.311
			s-251.64,302.133-53.449,611.468c164.772,257.176,412.506,238.36,412.506,238.36V849.775z"/>
                    <path fill="#ED9801" d="M629.076,1724.693c0,0,247.732,18.815,412.506-238.36c198.19-309.335,17.108-522.095-53.449-611.468
			s-210.104-83.886-263.416-53.311c-53.311,30.575-95.641,28.221-95.641,28.221V1724.693z"/>
                </g>
            </g>
        </SvgIcon>
    )
}