import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fill="#892709" d="M183.229,527.202c0,0-24.11,66.109,29.555,120.507c53.665,54.4,124.444-30.593,124.444-30.593
			s-67.063-76.59-76.534-113.895C188.94,522.31,183.229,527.202,183.229,527.202z"/>
                    <path fill="#D42A57" d="M816.396,624.79c0.503-8.717-1.727-17.664-6.973-25.469c-8.259-12.293-21.959-18.735-35.744-18.299
			c-0.889-1.88-1.928-3.719-3.124-5.501c-9.355-13.923-25.688-20.342-41.221-17.753c-1.168-3.912-2.934-7.717-5.331-11.284
			c-8.158-12.142-21.627-18.574-35.24-18.307c-13.119-17.602-37.887-21.983-56.309-9.605c-7.302-10.868-18.855-17.159-30.963-18.172
			c-12.966-18.221-38.164-22.907-56.841-10.355c-5.606,3.767-9.989,8.672-13.066,14.198c-13.04-6.847-29.324-6.512-42.406,2.28
			c-3.911,2.627-7.221,5.813-9.915,9.368c-9.885-1.494-20.336,0.562-29.288,6.577c-0.847,0.569-1.657,1.174-2.448,1.793
			c-13.568-9.161-31.878-9.75-46.303-0.057c-2.403,1.614-4.581,3.438-6.531,5.429c-8.446-0.317-17.066,1.933-24.619,7.008
			c-4.561,3.064-8.307,6.882-11.206,11.169c-12.395-5.142-27.045-4.183-39.018,3.862c-11.052,7.427-17.373,19.25-18.219,31.581
			c-9.086-0.783-18.486,1.402-26.643,6.884c-5.172,3.475-9.301,7.919-12.327,12.92c-9.833-1.443-20.213,0.627-29.11,6.605
			c-8.4,5.644-14.065,13.826-16.677,22.841c-4.815,1.102-9.516,3.078-13.861,5.998c-8.814,5.924-14.62,14.643-17.042,24.186
			c-9.749-1.364-20.016,0.717-28.83,6.64c-14.468,9.721-20.833,26.971-17.411,43.035c-0.856,0.492-1.706,1.003-2.538,1.563
			c-11.972,8.044-18.396,21.249-18.319,34.666c-5.061,1.064-10.006,3.09-14.562,6.151c-15.392,10.342-21.624,29.21-16.642,46.102
			c-5.129,1.053-10.147,3.094-14.764,6.197c-18.992,12.762-24.042,38.504-11.28,57.497c-18.993,12.762-24.042,38.504-11.281,57.496
			c0.109,0.163,0.227,0.315,0.337,0.476c-14.436,13.657-17.334,36.127-5.883,53.168c0.108,0.162,0.226,0.314,0.336,0.474
			c-14.435,13.657-17.333,36.128-5.882,53.168c4.682,6.968,11.115,12.052,18.317,15.075c-2.754,10.987-1.021,23.053,5.793,33.193
			c2.759,4.106,6.126,7.557,9.901,10.321c0.632,1.701,1.24,3.403,1.902,5.104c-15.193,13.565-18.441,36.593-6.761,53.976
			c6.015,8.95,14.915,14.801,24.631,17.154c1.151,4.311,3.011,8.508,5.639,12.419c6.154,9.159,15.332,15.065,25.313,17.306
			c-1.699,10.087,0.302,20.815,6.457,29.975c7.981,11.878,21.04,18.301,34.348,18.326c0.953,2.126,2.088,4.204,3.432,6.203
			c4.21,6.266,9.837,11.004,16.166,14.097c0.472,7.026,2.732,14.026,6.942,20.292c7.426,11.051,19.248,17.371,31.576,18.219
			c1.068,2.662,2.422,5.257,4.087,7.733c10.793,16.063,30.87,22.15,48.297,15.927c0.542,0.362,1.083,0.725,1.626,1.084
			c0.801,6.159,2.994,12.229,6.692,17.733c10.425,15.515,29.512,21.721,46.506,16.517c0.309,0.505,0.623,1.011,0.957,1.506
			c6.53,9.719,16.463,15.775,27.148,17.674c0.944,2.086,2.061,4.123,3.379,6.086c12.412,18.47,37.1,23.749,55.917,12.284
			c1.611,0.604,3.224,1.198,4.837,1.786c1.116,2.992,2.584,5.9,4.442,8.668c10.81,16.086,30.929,22.166,48.374,15.896
			c10.077,8.013,23.386,10.87,35.826,7.761c13.784,11.941,34.353,13.721,50.218,3.061c0.698-0.471,1.369-0.962,2.029-1.461
			c11.415,8.308,26.527,10.313,39.85,4.939c0.162,0.254,0.315,0.511,0.486,0.764c12.762,18.99,38.505,24.043,57.497,11.281
			c4.772-3.207,8.663-7.235,11.612-11.771c12.151,4.637,26.292,3.507,37.91-4.299c5.38-3.615,9.638-8.273,12.692-13.525
			c12.247,4.832,26.588,3.766,38.343-4.135c6.267-4.21,11.004-9.839,14.097-16.166c7.027-0.472,14.026-2.732,20.293-6.942
			c9.16-6.155,15.066-15.333,17.307-25.314c10.088,1.696,20.816-0.302,29.975-6.458c10.629-7.141,16.887-18.346,18.105-30.159
			c2.962-1.11,5.846-2.568,8.584-4.409c15.271-10.263,21.52-28.919,16.746-45.711c2.741-1.081,5.414-2.461,7.963-4.172
			c15.135-10.17,21.402-28.584,16.87-45.255c0.622-0.372,1.241-0.761,1.852-1.169c10.438-7.014,16.649-17.948,18.023-29.526
			c12.698-12.231,16.276-31.344,8.76-47.362c3.1-7.998,3.689-16.866,1.515-25.324c14.836-13.61,17.911-36.384,6.34-53.606
			c-1.196-1.778-2.508-3.438-3.914-4.967c4.023-9.034,4.757-19.359,1.801-29.009c12.306-13.784,14.237-34.641,3.458-50.682
			c-1.197-1.781-2.506-3.44-3.914-4.971c5.084-11.409,4.917-24.887-1.298-36.459c14.563-13.643,17.514-36.209,6.025-53.304
			c-2.922-4.35-6.524-7.955-10.568-10.793c0.99-9.333-1.163-19.05-6.808-27.45c-0.194-0.288-0.403-0.565-0.604-0.847
			c1.286-9.667-0.809-19.819-6.674-28.549c-0.243-0.361-0.5-0.707-0.751-1.06c12.287-13.783,14.209-34.628,3.435-50.662
			c-7.061-10.51-18.1-16.741-29.769-18.054c3.19-11.302,1.593-23.877-5.469-34.388c-2.701-4.019-5.99-7.407-9.666-10.141
			c-1.144-4.437-3.037-8.763-5.738-12.781c-3.994-5.944-9.259-10.523-15.193-13.615c-0.621-6.664-2.87-13.269-6.865-19.213
			c-3.232-4.813-7.302-8.723-11.879-11.682c-1.011-5.358-3.096-10.604-6.328-15.415C832.098,633.062,824.656,627.616,816.396,624.79
			z"/>
                    <circle fill="#A51743" cx="215.147" cy="851.638" r="29.912"/>
                    <circle fill="#A51743" cx="196.26" cy="984.425" r="33.798"/>
                    <circle fill="#A51743" cx="326.06" cy="1065.961" r="33.798"/>
                    <circle fill="#A51743" cx="224.136" cy="1116.737" r="23.181"/>
                    <circle fill="#A51743" cx="455.455" cy="1089.063" r="27.478"/>
                    <path fill="#A51743" d="M536.165,1258.439c10.325,15.365,6.237,36.189-9.127,46.514c-15.365,10.325-36.191,6.24-46.515-9.123
			c-10.327-15.368-6.238-36.192,9.126-46.518C505.014,1238.988,525.839,1243.072,536.165,1258.439z"/>
                    <circle fill="#A51743" cx="434.302" cy="1190.158" r="23.844"/>
                    <circle fill="#A51743" cx="345.389" cy="1206.355" r="27.478"/>
                    <circle fill="#A51743" cx="640.671" cy="1305.329" r="14.459"/>
                    <path fill="#DD4E7A" d="M626,611.607c9.915,14.751,5.988,34.748-8.766,44.662c-14.752,9.914-34.749,5.992-44.661-8.762
			c-9.915-14.755-5.991-34.75,8.761-44.664C596.088,592.929,616.086,596.85,626,611.607z"/>
                    <circle fill="#DD4E7A" cx="690.479" cy="716.456" r="20.592"/>
                    <path fill="#DD4E7A" d="M695.43,637.607c6.344,9.438,3.836,22.231-5.606,28.577c-9.437,6.341-22.232,3.832-28.575-5.608
			c-6.341-9.438-3.831-22.233,5.606-28.577C676.294,625.657,689.088,628.17,695.43,637.607z"/>
                    <circle fill="#DD4E7A" cx="678.563" cy="803.954" r="20.864"/>
                    <circle fill="#DD4E7A" cx="734.732" cy="839.577" r="14.262"/>
                    <circle fill="#DD4E7A" cx="623.575" cy="722.612" r="27.026"/>
                    <circle fill="#DD4E7A" cx="761.939" cy="722.792" r="27.026"/>
                </g>
                <g>
                    <g>
                        <path fill="#FFDCC0" d="M1184.744,1371.741c0,162.635-150.779,294.472-336.778,294.472
				c-185.994,0-336.776-131.837-336.776-294.472c0-162.628,150.782-294.469,336.776-294.469
				C1033.965,1077.272,1184.744,1209.113,1184.744,1371.741z"/>
                        <path fill="#FFE8DA" d="M1084.517,1288.178c-20.622,29.544-82.155,22.218-137.44-16.366s-83.384-93.816-62.763-123.361
				c20.619-29.546,82.153-22.219,137.438,16.367C1077.036,1203.398,1105.135,1258.632,1084.517,1288.178z"/>
                        <path fill="#B51A52" d="M507.24,1323.807c0,9.665,4.339,18.311,11.173,24.112c-6.834,5.799-11.173,14.446-11.173,24.109
				c0,3.695,0.641,7.24,1.804,10.539c-1.164,3.298-1.804,6.846-1.804,10.538c0,5.468,1.386,10.609,3.828,15.093
				c-2.442,4.485-3.828,9.627-3.828,15.095c0,4.423,0.913,8.634,2.553,12.456c-1.641,3.826-2.553,8.037-2.553,12.46
				c0,7.178,2.394,13.797,6.424,19.102c-4.03,5.308-6.424,11.924-6.424,19.102c0,11.305,5.934,21.217,14.855,26.81
				c-0.242,1.569-0.364,3.178-0.364,4.813c0,12.803,7.614,23.822,18.556,28.798c-0.926,2.973-1.426,6.133-1.426,9.413
				c0,11.164,5.793,20.975,14.531,26.598c0.816,16.736,14.643,30.057,31.581,30.057c0,9.993,4.64,18.896,11.88,24.691
				c0.459,17.062,14.425,30.756,31.599,30.756c5.156,0,10.022-1.243,14.323-3.433c5.343,9.889,15.804,16.607,27.833,16.607
				c3.596,0,7.048-0.61,10.268-1.715c5.628,5.155,13.123,8.303,21.353,8.303c0.78,0,1.551-0.038,2.314-0.096
				c4.696,11.578,16.044,19.749,29.308,19.749c2.21,0,4.365-0.229,6.446-0.661c5.219,3.797,11.638,6.04,18.582,6.04
				c4.193,0,8.192-0.822,11.854-2.306c5.661,8.534,15.353,14.164,26.361,14.164c10.162,0,19.198-4.802,24.983-12.251
				c5.42,4.363,12.307,6.979,19.807,6.979c4.756,0,9.265-1.058,13.311-2.935c5.614,5.098,13.07,8.206,21.252,8.206
				c7.724,0,14.796-2.774,20.287-7.371c3.521,1.354,7.338,2.1,11.333,2.1c8.106,0,15.496-3.053,21.093-8.068
				c5.595,5.016,12.985,8.068,21.089,8.068c13.304,0,24.68-8.216,29.349-19.85c0.753,0.049,1.509,0.092,2.274,0.092
				c11.007,0,20.696-5.63,26.361-14.164c3.658,1.479,7.655,2.303,11.842,2.303c14.152,0,26.134-9.301,30.167-22.122
				c3.698,1.515,7.747,2.359,11.99,2.359c17.465,0,31.622-14.158,31.622-31.622c17.465,0,31.623-14.157,31.623-31.62
				c0-0.151-0.014-0.298-0.014-0.445c14.959-2.508,26.359-15.507,26.359-31.176c0-0.147-0.01-0.297-0.012-0.446
				c14.959-2.505,26.361-15.505,26.361-31.174c0-6.409-1.913-12.367-5.187-17.347c6.421-5.79,10.457-14.168,10.457-23.495
				c0-3.777-0.663-7.394-1.877-10.75c0.324-1.35,0.663-2.686,0.967-4.042c15.4-2.129,27.259-15.336,27.259-31.316
				c0-8.231-3.147-15.728-8.3-21.352c1.105-3.223,1.714-6.672,1.714-10.269c0-8.423-3.299-16.07-8.669-21.736
				c5.37-5.667,8.669-13.317,8.669-21.738c0-10.921-5.539-20.551-13.955-26.232c0.299-1.752,0.465-3.552,0.465-5.392
				c0-5.761-1.548-11.155-4.24-15.81c1.579-2.726,2.75-5.715,3.458-8.877h-681.68C507.25,1323.61,507.24,1323.706,507.24,1323.807z"
                        />
                    </g>
                    <path fill="#A51743" d="M589.673,1414.133c0,12.61-10.223,22.832-22.832,22.832c-12.606,0-22.829-10.222-22.829-22.832
			c0-12.607,10.223-22.829,22.829-22.829C579.45,1391.304,589.673,1401.525,589.673,1414.133z"/>
                    <circle fill="#A51743" cx="651.491" cy="1564.49" r="24.711"/>
                    <path fill="#A51743" d="M777.625,1642.504c0,9.768-7.922,17.687-17.694,17.687c-9.768,0-17.69-7.919-17.69-17.687
			c0-9.774,7.923-17.694,17.69-17.694C769.703,1624.81,777.625,1632.729,777.625,1642.504z"/>
                    <circle fill="#A51743" cx="676.202" cy="1470.15" r="18.198"/>
                    <path fill="#A51743" d="M869.551,1642.504c0,6.091-4.943,11.032-11.035,11.032c-6.097,0-11.036-4.941-11.036-11.032
			c0-6.098,4.939-11.04,11.036-11.04C864.607,1631.464,869.551,1636.406,869.551,1642.504z"/>
                    <circle fill="#DD4E7A" cx="1096.939" cy="1391.304" r="24.563"/>
                    <circle fill="#DD4E7A" cx="1121.503" cy="1472.631" r="15.717"/>
                    <path fill="#DD4E7A" d="M1045.432,1513.37c0,6.009-4.874,10.886-10.886,10.886s-10.887-4.877-10.887-10.886
			c0-6.013,4.875-10.886,10.887-10.886S1045.432,1507.357,1045.432,1513.37z"/>
                </g>
            </g>
        </SvgIcon>
    )
}