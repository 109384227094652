import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#64AC15" d="M750.808,1135.07c0,0,175.504,85.617,176.838,253.266c0.64,81.135-77.225,220.062-163.553,304.187
		C764.093,1692.522,381.999,1563.416,750.808,1135.07z"/>
                <path fill="#4E7857" d="M620.943,1167.546c0,0,175.502,85.617,176.837,253.267c0.64,81.134-77.224,220.062-163.553,304.188
		C634.228,1725,252.134,1595.892,620.943,1167.546z"/>
                <path fill="#42720F" d="M396.331,1154.82c0,0-142.638,104.333-117.025,253.884c12.404,72.374,103.981,183.672,194.331,244.812
		C473.637,1653.516,793.354,1477.419,396.331,1154.82z"/>
                <path fill="#42720F" d="M892.853,1182.313c0,0,132.191,78.915,122.117,210.709c-4.876,63.773-75.272,167.75-148.671,228.127
		C866.299,1621.149,574.69,1494.373,892.853,1182.313z"/>
                <path fill="#6A8F2E" d="M772.473,1132.943c0,0,134.479,74.959,128.322,206.988c-2.977,63.893-70.255,169.912-141.828,232.44
		C758.967,1572.372,463.718,1454.313,772.473,1132.943z"/>
                <path fill="#64AC15" d="M414.221,1519.418c0,0,117.436-210.598-126.408-372.893
		C287.813,1146.525,111.318,1365.664,414.221,1519.418z"/>
                <path fill="#64AC15" d="M603.977,897.496c0,0,175.503,85.617,176.838,253.266c0.64,81.135-77.225,220.063-163.553,304.187
		C617.261,1454.948,235.168,1325.842,603.977,897.496z"/>
                <path fill="#4E7857" d="M253.863,1355.928c0,0-241.379-173.919-28.278-365.164C225.585,990.764,548.615,1044.15,253.863,1355.928z"
                />
                <path fill="#4E7857" d="M878.487,1413.797c0,0,290.211-65.486,169.042-324.912
		C1047.529,1088.885,729.397,1011.488,878.487,1413.797z"/>
                <path fill="#42720F" d="M436.398,909.511c0,0,118.198,98.646,87.599,227.237c-14.814,62.232-100.63,153.89-182.583,202.006
		C341.415,1338.754,73.272,1167.851,436.398,909.511z"/>
                <path fill="#42720F" d="M783.66,909.511c0,0-118.198,98.646-87.599,227.237c14.814,62.232,100.63,153.89,182.582,202.006
		C878.644,1338.754,1146.788,1167.851,783.66,909.511z"/>
                <path fill="#4E7857" d="M985.781,1043.032c0,0-53.437,144.382-184.854,158.557c-63.6,6.854-178.685-43.362-251.44-104.502
		C549.487,1097.087,620.861,787.224,985.781,1043.032z"/>
                <path fill="#64AC15" d="M1085.521,1190.067c0,0,104.112-247.581-177.786-354.438
		C907.735,835.629,740.59,1106.882,1085.521,1190.067z"/>
                <path fill="#6A8F2E" d="M1047.81,924.601c0,0-226.56,178.935-361.233-59.14C686.576,865.461,815.679,584.312,1047.81,924.601z"/>
                <path fill="#6A8F2E" d="M715.107,973.623c0,0-53.437,144.384-184.853,158.559c-63.6,6.854-178.685-43.364-251.44-104.503
		C278.815,1027.679,350.189,717.815,715.107,973.623z"/>
                <path fill="#42720F" d="M145.54,1074.627c0,0,39.927-245.989,251.923-264.272C397.463,810.354,472.645,1017.071,145.54,1074.627z"
                />
                <path fill="#4E7857" d="M541.82,543.731c0-33.543,27.189-60.731,60.733-60.731l0,0c33.543,0,60.732,27.188,60.732,60.731
		l28.921,232.774c0,33.543-56.11,60.732-89.653,60.732l0,0c-33.543,0-89.649-30.078-89.649-63.621L541.82,543.731z"/>
                <path fill="#42720F" d="M897.533,903.757c0,0-214.011,193.771-364.394-34.703C533.14,869.054,643.035,579.854,897.533,903.757z"/>
                <path fill="#64AC15" d="M244.314,881.059c0,0,175.029-229.593,364.605-32.416C608.92,848.643,553.678,1153.041,244.314,881.059z"/>
            </g>

        </SvgIcon>
    )
}