import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#D8E8C3" d="M132.984,1055.678c0,0-56.699-133.564,4.99-190.768c61.69-57.203,2.372-103.813,71.146-155.672
		c68.773-51.858,57.793-39.763,77.767-93.243c19.973-53.479,89.443-39.967,116.993-54.381c27.55-14.414,46.031-63.46,148.644-54.584
		c102.612,8.876,72.625-54.51,185.175-4.787s101.539-28.989,177.255,74.461c0,0,99.05,13.597,134.294,99.477
		c35.246,85.881,112.175,77.231,100.938,178.062c-11.236,100.831,122.532,75.781-20.327,277.389
		c-142.857,201.605-496.65,314.457-496.65,314.457l-326.769-26.092L132.984,1055.678z"/>
                <path fill="#B2C19A" d="M834.16,963.33c-31.804,4.449-61.5-14.265-72.064-43.272c-0.329,4.612-0.194,9.324,0.474,14.09
		c5.115,36.568,38.907,62.063,75.475,56.948c36.568-5.116,62.065-38.905,56.95-75.473c-0.666-4.766-1.832-9.333-3.412-13.679
		C889.383,932.737,865.965,958.881,834.16,963.33z"/>
                <path fill="#B2C19A" d="M965.157,1127.452c-31.804,4.448-61.499-14.266-72.063-43.273c-0.329,4.612-0.194,9.324,0.473,14.09
		c5.116,36.568,38.907,62.063,75.475,56.948c36.569-5.116,62.066-38.904,56.95-75.474c-0.666-4.766-1.831-9.333-3.411-13.678
		C1020.381,1096.858,996.962,1123.001,965.157,1127.452z"/>
                <path fill="#B2C19A" d="M722.934,1070.768c-26.293,3.679-50.841-11.792-59.574-35.772c-0.271,3.813-0.159,7.708,0.392,11.647
		c4.229,30.229,32.165,51.306,62.393,47.076c30.229-4.229,51.305-32.16,47.076-62.39c-0.552-3.939-1.513-7.716-2.819-11.307
		C768.582,1045.478,749.224,1067.09,722.934,1070.768z"/>
                <path fill="#B2C19A" d="M254.758,983.736c-26.292,3.679-50.84-11.792-59.574-35.772c-0.271,3.813-0.159,7.707,0.392,11.646
		c4.229,30.229,32.165,51.306,62.393,47.077c30.228-4.229,51.304-32.16,47.075-62.39c-0.552-3.939-1.512-7.716-2.819-11.307
		C300.407,958.446,281.048,980.058,254.758,983.736z"/>
                <path fill="#B2C19A" d="M641.22,575.558c-26.293,3.678-50.841-11.792-59.575-35.772c-0.27,3.812-0.159,7.707,0.392,11.647
		c4.229,30.229,32.165,51.304,62.394,47.075c30.228-4.229,51.304-32.16,47.076-62.389c-0.552-3.94-1.513-7.716-2.82-11.308
		C686.869,550.268,667.51,571.88,641.22,575.558z"/>
                <path fill="#B2C19A" d="M432.376,642.301c-31.576,4.417-61.054-14.162-71.544-42.962c-0.325,4.579-0.19,9.258,0.473,13.991
		c5.078,36.301,38.626,61.612,74.929,56.534c36.305-5.079,61.616-38.625,56.537-74.925c-0.662-4.733-1.817-9.27-3.386-13.583
		C487.2,611.928,463.952,637.884,432.376,642.301z"/>
                <path fill="#B2C19A" d="M287.444,831.329c-31.805,4.45-61.499-14.265-72.064-43.272c-0.329,4.612-0.193,9.324,0.473,14.09
		c5.116,36.568,38.907,62.063,75.475,56.947c36.568-5.116,62.066-38.905,56.95-75.473c-0.667-4.766-1.832-9.334-3.413-13.679
		C342.667,800.735,319.249,826.88,287.444,831.329z"/>
                <path fill="#B2C19A" d="M680.532,859.826c-35.094,4.909-67.863-15.738-79.521-47.748c-0.361,5.091-0.213,10.287,0.523,15.546
		c5.645,40.351,42.933,68.483,83.282,62.838c40.351-5.645,68.485-42.93,62.841-83.28c-0.735-5.259-2.02-10.297-3.766-15.094
		C741.466,826.069,715.625,854.916,680.532,859.826z"/>
                <path fill="#B2C19A" d="M770.333,1180.77c-35.094,4.908-67.862-15.739-79.52-47.749c-0.362,5.091-0.214,10.287,0.523,15.546
		c5.645,40.352,42.932,68.484,83.281,62.84c40.351-5.646,68.485-42.93,62.841-83.28c-0.735-5.26-2.02-10.298-3.765-15.094
		C831.267,1147.013,805.427,1175.859,770.333,1180.77z"/>
                <path fill="#B2C19A" d="M440.442,901.842c-35.094,4.909-67.862-15.738-79.52-47.749c-0.361,5.091-0.213,10.288,0.524,15.547
		c5.645,40.351,42.932,68.483,83.281,62.838c40.351-5.645,68.486-42.93,62.841-83.281c-0.735-5.259-2.02-10.297-3.765-15.093
		C501.375,868.085,475.536,896.932,440.442,901.842z"/>
                <path fill="#B2C19A" d="M1047.065,1000.6c-40.091,5.608-77.527-17.979-90.845-54.55c-0.414,5.816-0.244,11.754,0.597,17.763
		c6.449,46.098,49.047,78.237,95.145,71.788c46.1-6.448,78.242-49.044,71.793-95.143c-0.842-6.008-2.309-11.764-4.301-17.244
		C1116.681,962.038,1087.16,994.991,1047.065,1000.6z"/>
                <path fill="#B2C19A" d="M794.084,693.656c-45.784,6.405-88.536-20.534-103.748-62.297c-0.471,6.641-0.277,13.424,0.684,20.285
		c7.365,52.646,56.012,89.348,108.656,81.984c52.646-7.366,89.354-56.009,81.988-108.654c-0.959-6.861-2.637-13.437-4.913-19.692
		C873.583,649.615,839.87,687.251,794.084,693.656z"/>
                <path fill="#B2C19A" d="M536.141,752.661c-46.729,6.537-90.359-20.959-105.88-63.579c-0.482,6.774-0.284,13.701,0.695,20.7
		c7.516,53.729,57.164,91.187,110.891,83.671c53.728-7.516,91.19-57.16,83.674-110.889c-0.979-7-2.689-13.714-5.015-20.096
		C617.276,707.71,582.87,746.124,536.141,752.661z"/>
                <path fill="#B2C19A" d="M970.608,854.274c-46.728,6.537-90.358-20.959-105.88-63.578c-0.481,6.773-0.284,13.701,0.694,20.699
		c7.518,53.73,57.165,91.188,110.892,83.671c53.729-7.516,91.19-57.16,83.674-110.89c-0.979-6.999-2.689-13.714-5.014-20.095
		C1051.744,809.324,1017.337,847.737,970.608,854.274z"/>
                <g>
                    <g>
                        <path fill="#7F9D2D" d="M607.149,1710.604c-26.458,3.701-58.627,1.787-93.025-5.536c-34.821-7.413-60.882-16.764-91.056-27.593
				c-51.332-18.418-109.509-39.293-229.892-57.276c-53.747-8.028-83.775-19.172-97.365-36.132
				c-12.32-15.375-12.361-37.547-12.419-68.24c-0.07-37.171-0.165-88.073-24.301-151.918
				c-53.602-141.782,13.033-245.412,41.486-289.663c4.313-6.705,7.719-12.002,10.029-16.328c1.962-3.665,5.074-8.146,8.77-8.663
				c8.929-1.25,23.887,11.367,44.46,37.497c1.914,2.435,3.449,4.369,4.509,5.586c12.984,14.881,36.471,38.898,74.608,68.775
				c39.631,31.052,75.131,33.766,97.936,30.575c8.705-1.218,15.819-3.313,21.034-5.266
				c128.092,152.018,323.587,403.911,320.858,455.268C680.711,1680.687,655.264,1703.873,607.149,1710.604z"/>
                        <path fill="#6C8F33" d="M122.38,1062.594c4.484,2.211,8.9,33.845,26.656,56.403c1.973,2.504,10.379,22.112,11.762,23.697
				c13.344,15.292,35.506-2.188,74.415,28.301c43.101,33.766,82.203,36.648,107.415,33.122c5.623-0.787,10.673-1.902,15.087-3.135
				c25.661,30.729,90.461,109.089,154.274,192.321c47.725,62.247,85.52,114.938,112.339,156.608
				c45.529,70.748,46.018,89.236,45.924,91.109c-0.636,11.985-2.575,48.443-64.841,57.154c-25.026,3.5-55.69,1.641-88.674-5.382
				c-33.992-7.239-59.682-16.457-89.43-27.128c-51.875-18.618-69.017-52.922-190.62-71.086
				c-49.021-7.324-119.93-4.444-131.084-18.361c-9.125-11.39,16.938-42.412,16.881-73.368
				c-0.073-38.132,4.659-79.018-20.284-144.994c-51.364-135.873-18.498-234.161,8.934-276.822
				c4.465-6.944,7.992-12.429,10.54-17.198C121.926,1063.363,122.164,1062.951,122.38,1062.594 M117.636,1036.827
				c-6.646,0.93-12.877,5.411-18.098,15.178c-16.788,31.412-115.883,147.854-52.187,316.341
				c63.697,168.487-45.842,235.907,143.97,264.263c189.813,28.354,224.413,64.342,320.189,84.733
				c35.173,7.49,68.762,9.693,97.376,5.689c49.299-6.896,83.851-32.21,86.426-80.677c4.067-76.598-329.913-471.262-329.913-471.262
				s-9.813,5.868-26.25,8.168c-20.928,2.927-52.592,0.076-88.458-28.024c-37.43-29.325-60.326-52.747-72.892-67.15
				C170.81,1076.077,141.536,1033.483,117.636,1036.827L117.636,1036.827z"/>
                        <path fill="#99B57B" d="M256.836,1428.082c-12.872-22.164-23.381-42.489-27.919-56.448c-0.165-0.501-0.325-1.015-0.487-1.523
				c-4.728-52.866,44.205-81.527,44.205-81.527c-46.255-10.14-52.54,29.228-49.26,63.706
				c-14.256-55.397-25.987-142.658-25.987-142.658c-0.532,1.795-12.915,44.697-5.25,105.857
				c1.535,12.239,5.501,27.114,11.624,43.674c-21.786-27.448-54.418-52.572-80.785-12.339c0,0,51.276-12.815,85.061,23.265
				c8.34,20.401,19.634,42.869,33.47,65.855c-23.317-20.357-50.637-32.002-74.831-8.902c0,0,50.651,9.521,88.724,30.836
				c23.742,35.683,53.168,71.378,86.77,101.326c0,0-47.335-66.835-81.448-124.483c-2.939-46.476,18.862-105.655,18.862-105.655
				C236.541,1340.955,243.756,1389.402,256.836,1428.082z"/>
                    </g>
                    <g>
                        <path fill="#7F9D2D" d="M747.781,1687.146c-39.223,5.487-66.883-0.456-89.688-19.271c-66.335-54.731-5.782-157.351,2.571-170.754
				c10.677-8.081,69.675-53.984,97.111-102.032c10.089-17.658,16.813-40.065,23.933-63.788
				c13.251-44.15,26.953-89.797,62.229-98.383c14.786-3.595,33.559-6.996,55.8-10.108c52.73-7.377,107.796-10.69,108.346-10.722
				l3.469-0.205l105.145-88.729l0.434-0.463c0.051-0.054,8.914-9.273,20.48-10.893c22.692-3.175,38.954,26.729,48.601,52.375
				c36.356,96.635,20.181,210.947-42.208,298.321c-25.35,35.501-29.579,63.09-32.978,85.257
				c-5.177,33.765-7.774,50.71-101.022,67.479c-54.319,9.769-104.982,26.446-149.684,41.16
				C819.519,1669.818,780.979,1682.503,747.781,1687.146z"/>
                        <path fill="#6C8F33" d="M1156.354,1163.925c35.142,93.418,30.621,185.686-29.772,270.261
				c-26.797,37.526-22.515,89.943-25.893,111.981c-2.684,17.496-4.203,25.521-12.149,32.399
				c-11.688,10.12-34.733-9.183-77.138-1.555c-55.04,9.898-110.945,17.559-155.973,32.38
				c-40.343,13.278-76.828,62.884-109.097,67.399c-36.072,5.046-61.233-0.192-81.584-16.982
				c-25.617-21.136-33.076-53.099-22.163-95.003c7.408-28.433,21.12-52.556,25.964-60.547c14.337-11.001,95.15-51.436,122.4-99.147
				c10.677-18.697,15.992-50.752,23.288-75.062c12.403-41.328,2.716-79.803,32.174-86.968c14.445-3.518,32.874-6.851,54.778-9.916
				c52.282-7.313,106.966-10.607,107.513-10.639l6.937-0.413l5.313-4.48c0,0,51.076-45.081,72.724-63.949
				C1115.324,1134.818,1156.354,1163.925,1156.354,1163.925z M1136.159,1101.44c-15.601,2.183-26.695,14.134-26.695,14.134
				l-102,86.073c0,0-55.391,3.282-109.176,10.806c-20.168,2.821-40.112,6.239-56.822,10.306
				c-61.271,14.907-63.933,116.649-92.771,167.145s-95.828,99.994-95.828,99.994s-80.215,121.039-1.428,186.045
				c27.381,22.594,60.09,26.836,97.791,21.562c70.781-9.902,159.125-53.37,262.621-71.982
				c158.624-28.526,75.32-65.444,140.659-156.949c65.339-91.504,80.472-209.774,43.488-308.083
				C1176.513,1108.69,1153.538,1099.01,1136.159,1101.44L1136.159,1101.44z"/>
                        <path fill="#99B57B" d="M896.539,1476.553c30.052-24.723,55.769-48.076,69.618-65.537c0.493-0.626,0.995-1.271,1.497-1.912
				c34.719-72.718-19.728-139.41-19.728-139.41c71.188,10.064,59.279,69.408,36.357,116.729
				c49.617-71.27,112.517-189.222,112.517-189.222c-0.196,2.84-5.294,70.442-48.587,153.407
				c-8.662,16.597-22.177,35.666-39.662,55.974c45.533-27.508,105.266-45.976,121.483,25.229c0,0-66.173-45.389-133.352-11.939
				c-22.675,24.615-50.639,50.599-82.499,75.981c43.956-16.624,88.993-18.722,111.192,26.957c0,0-77.113-13.274-142.574-3.108
				c-52.675,38.199-113.446,73.407-177.114,98.229c0,0,102.739-70.034,181.807-133.994c28.794-64.577,29.105-160.317,29.105-160.317
				C971.553,1363.325,935.633,1428.443,896.539,1476.553z"/>
                    </g>
                    <g>
                        <path fill="#7F9D2D" d="M524.375,932.739c0,0-209.147,29.255-257.972,214.894c-48.824,185.639-70.234,294.229,2.852,338.912
				c73.089,44.686,171.606,180.141,225.208,210.656c53.602,30.516,230.152,18.492,241.917-170.41
				c11.767-188.901-22.583-172.832-53.755-202.262c-31.173-29.429-22.532-70.059-28.684-136.777
				C647.791,1121.032,593.454,914.627,524.375,932.739z"/>
                        <g>
                            <path fill="#6C8F33" d="M525.967,982.847c6.561,2.967,34.12,10.215,57.404,74.208c18.021,49.534,30.495,106.09,33.083,134.154
					c1.477,16.013,2.045,30.333,2.594,44.181c1.716,43.22-69.25,85.923-34.852,118.395c7.342,6.933,56.894,21.079,62.635,25.028
					c10.85,7.461,16.144,8.68,19.902,21.501c6.92,23.607,36.161,58.521,32.075,124.136c-2.924,46.944-17.548,83.639-43.462,109.063
					c-25.836,25.349-57.217,34.299-78.993,37.346c-30.666,4.29-61.252-29.911-69.882-34.825
					c-20.338-11.578-28.363-47.686-62.462-84.184c-45.009-48.176-112.17-71.168-155.118-97.424
					c-15.724-9.613-31.485-26.979-29.163-82.919c2.078-50.127-7.487-113.057,18.226-210.821
					c17.26-65.62,68.533-84.268,135.081-119.643C465.175,1013.323,525.967,982.847,525.967,982.847 M528.357,931.938
					c-1.325,0.185-2.645,0.449-3.982,0.801c0,0-209.147,29.255-257.972,214.894c-48.824,185.639-70.234,294.229,2.852,338.912
					c73.089,44.686,171.606,180.141,225.208,210.656c18.414,10.483,51.341,15.946,87.106,10.941
					c68.352-9.561,147.086-57.336,154.812-181.352c11.767-188.901-22.583-172.832-53.755-202.262
					c-31.173-29.429-22.532-70.059-28.684-136.777C647.91,1122.322,595.524,922.542,528.357,931.938L528.357,931.938z"/>
                        </g>
                        <path fill="#99B57B" d="M501.075,1431.994c-11.241-39.42-19.54-75.056-21.086-98.479c-0.055-0.838-0.104-1.698-0.155-2.552
				c13.457-83.809,100.575-108.998,100.575-108.998c-67.716-33.907-92.933,24.649-101.404,79.383
				c-0.319-91.473,15.804-231.352,15.804-231.352c-1.528,2.578-37.592,64.212-49.755,162.031c-2.433,19.57-2.139,44.187,0.847,72.26
				c-22.98-51.104-63.684-102.881-120.374-50.885c0,0,84.522,0.294,122.71,69.502c4.905,34.912,13.578,74.175,25.989,115.246
				c-28.14-40.724-65.91-69.514-112.497-43.222c0,0,74.77,34.673,125.409,82.683c22.773,64.646,54.351,131.546,94.663,191.176
				c0,0-47.096-122.21-77.308-224.981c13.712-73.197,70.775-156.354,70.775-156.354
				C503.871,1288.956,496.004,1366.894,501.075,1431.994z"/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}