import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208" {...props}>
            <g>
                <path fill="#BE6507" d="M633.223,1724.884c194.806,2.435,316.559-29.221,345.779-489.448S608.872,483,608.872,483
		s-375,292.208-345.779,752.436S438.418,1727.318,633.223,1724.884z"/>
                <g>
                    <path fill="#AD5D04" d="M458.5,797.5c-8.837,0-16,7.163-16,16v99c0,8.837,7.163,16,16,16s16-7.163,16-16v-99
			C474.5,804.663,467.337,797.5,458.5,797.5z"/>
                    <path fill="#AD5D04" d="M606.5,828.5c-8.837,0-16,7.163-16,16v103c0,8.837,7.163,16,16,16s16-7.163,16-16v-103
			C622.5,835.663,615.337,828.5,606.5,828.5z"/>
                    <path fill="#AD5D04" d="M778.5,787.5c-8.836,0-16,7.163-16,16v97c0,8.837,7.164,16,16,16s16-7.163,16-16v-97
			C794.5,794.663,787.336,787.5,778.5,787.5z"/>
                    <path fill="#AD5D04" d="M458.5,1466.5c-8.837,0-16,7.163-16,16v99c0,8.837,7.163,16,16,16s16-7.163,16-16v-99
			C474.5,1473.663,467.337,1466.5,458.5,1466.5z"/>
                    <path fill="#AD5D04" d="M606.5,1492.5c-8.837,0-16,7.163-16,16v98c0,8.837,7.163,16,16,16s16-7.163,16-16v-98
			C622.5,1499.663,615.337,1492.5,606.5,1492.5z"/>
                    <path fill="#AD5D04" d="M778.5,1452.5c-8.836,0-16,7.163-16,16v93c0,8.837,7.164,16,16,16s16-7.163,16-16v-93
			C794.5,1459.663,787.336,1452.5,778.5,1452.5z"/>
                    <path fill="#AD5D04" d="M458.5,1152.5c-8.837,0-16,7.163-16,16v92c0,8.837,7.163,16,16,16s16-7.163,16-16v-92
			C474.5,1159.663,467.337,1152.5,458.5,1152.5z"/>
                    <path fill="#AD5D04" d="M606.5,1173.5c-8.837,0-16,7.163-16,16v71c0,8.837,7.163,16,16,16s16-7.163,16-16v-71
			C622.5,1180.663,615.337,1173.5,606.5,1173.5z"/>
                    <path fill="#AD5D04" d="M778.5,1113.5c-8.836,0-16,7.163-16,16v107c0,8.837,7.164,16,16,16s16-7.163,16-16v-107
			C794.5,1120.663,787.336,1113.5,778.5,1113.5z"/>
                    <path fill="#AD5D04" d="M383.5,1120v-110c0-8.837-7.163-16-16-16s-16,7.163-16,16v110c0,8.837,7.163,16,16,16
			S383.5,1128.837,383.5,1120z"/>
                    <path fill="#AD5D04" d="M515.5,988c-8.837,0-16,7.163-16,16v98c0,8.837,7.163,16,16,16s16-7.163,16-16v-98
			C531.5,995.163,524.337,988,515.5,988z"/>
                    <path fill="#AD5D04" d="M687.5,982c-8.836,0-16,7.163-16,16v87c0,8.837,7.164,16,16,16s16-7.163,16-16v-87
			C703.5,989.163,696.336,982,687.5,982z"/>
                    <path fill="#AD5D04" d="M874.5,997.5c-8.836,0-16,7.163-16,16v70c0,8.837,7.164,16,16,16s16-7.163,16-16v-70
			C890.5,1004.663,883.336,997.5,874.5,997.5z"/>
                    <path fill="#AD5D04" d="M372.5,1305c-8.837,0-16,7.163-16,16v102c0,8.837,7.163,16,16,16s16-7.163,16-16v-102
			C388.5,1312.163,381.337,1305,372.5,1305z"/>
                    <path fill="#AD5D04" d="M515.5,1330c-8.837,0-16,7.163-16,16v105c0,8.837,7.163,16,16,16s16-7.163,16-16v-105
			C531.5,1337.163,524.337,1330,515.5,1330z"/>
                    <path fill="#AD5D04" d="M687.5,1283c-8.836,0-16,7.163-16,16v112c0,8.837,7.164,16,16,16s16-7.163,16-16v-112
			C703.5,1290.163,696.336,1283,687.5,1283z"/>
                    <path fill="#AD5D04" d="M869.5,1339.5c-8.836,0-16,7.163-16,16v60c0,8.837,7.164,16,16,16s16-7.163,16-16v-60
			C885.5,1346.663,878.336,1339.5,869.5,1339.5z"/>
                </g>
            </g>
        </SvgIcon>
    )
}