import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#204D14" d="M645.7,692.722V483l-49.401,27.624v182.098
		c-38.254,7.731-66.308,32.951-66.308,62.89c0,36.089,40.746,65.344,91.008,65.344s91.008-29.255,91.008-65.344
		C712.008,725.673,683.955,700.453,645.7,692.722z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#61A923" d="M621,1725c83.533,0,142.814-61.078,142.814-61.078
		c366.469-222.756,316.169-589.224,316.169-589.224C1004.778,698.67,621,738.769,621,738.769s-383.778-40.098-458.984,335.93
		c0,0-50.299,366.468,316.169,589.224C478.185,1663.922,537.467,1725,621,1725z"/>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M161.118,1095.415c13.067,27.205,35.533,56.021,73.918,79.353
			c0,0,17.04,17.557,41.052,17.557c24.011,0,41.053-17.557,41.053-17.557c38.384-23.331,60.85-52.147,73.918-79.353
			c-33.82,19.572-73.084,30.78-114.971,30.78C234.203,1126.195,194.938,1114.987,161.118,1095.415z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M391.059,1095.415c13.069,27.205,35.534,56.021,73.918,79.353
			c0,0,17.041,17.557,41.052,17.557s41.053-17.557,41.053-17.557c38.384-23.331,60.85-52.147,73.918-79.353
			c-33.82,19.572-73.084,30.78-114.971,30.78C464.144,1126.195,424.878,1114.987,391.059,1095.415z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M621,1095.415c13.069,27.205,35.533,56.021,73.92,79.353
			c0,0,17.039,17.557,41.051,17.557c24.011,0,41.053-17.557,41.053-17.557c38.384-23.331,60.85-52.147,73.918-79.353
			c-33.82,19.572-73.085,30.78-114.971,30.78S654.819,1114.987,621,1095.415z"/>
                </g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M276.088,880.759c13.069,27.208,35.534,56.024,73.919,79.353
			c0,0,17.041,17.558,41.052,17.558s41.053-17.558,41.053-17.558c38.384-23.328,60.85-52.145,73.917-79.353
			c-33.819,19.573-73.084,30.781-114.97,30.781C349.173,911.54,309.908,900.332,276.088,880.759z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M506.029,880.759c13.069,27.208,35.534,56.024,73.919,79.353
			c0,0,17.041,17.558,41.052,17.558c24.012,0,41.053-17.558,41.053-17.558c38.384-23.328,60.85-52.145,73.918-79.353
			C702.15,900.332,662.886,911.54,621,911.54S539.849,900.332,506.029,880.759z"/>
                </g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M268.903,1307.388c13.069,27.206,35.534,56.022,73.919,79.353
			c0,0,17.041,17.558,41.052,17.558s41.052-17.558,41.052-17.558c38.384-23.33,60.85-52.146,73.918-79.353
			c-33.819,19.573-73.084,30.781-114.97,30.781C341.987,1338.169,302.723,1326.961,268.903,1307.388z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M498.844,1307.388c13.069,27.206,35.534,56.022,73.919,79.353
			c0,0,17.041,17.558,41.052,17.558c24.011,0,41.053-17.558,41.053-17.558c38.384-23.33,60.85-52.146,73.918-79.353
			c-33.82,19.573-73.085,30.781-114.971,30.781C571.928,1338.169,532.664,1326.961,498.844,1307.388z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M843.755,1338.169c-41.886,0-81.15-11.208-114.97-30.781
			c13.069,27.206,35.533,56.022,73.919,79.353c0,0,17.04,17.558,41.051,17.558c24.012,0,41.053-17.558,41.053-17.558
			c38.385-23.33,60.851-52.146,73.918-79.353C924.906,1326.961,885.642,1338.169,843.755,1338.169z"/>
                </g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M372.878,1501.517c9.401,19.571,25.562,40.3,53.176,57.085
			c0,0,12.258,12.629,29.532,12.629c17.273,0,29.532-12.629,29.532-12.629c27.613-16.785,43.773-37.514,53.175-57.085
			c-24.33,14.08-52.576,22.143-82.707,22.143C425.454,1523.659,397.208,1515.597,372.878,1501.517z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M538.293,1501.517c9.401,19.571,25.562,40.3,53.175,57.085
			c0,0,12.259,12.629,29.532,12.629c17.272,0,29.532-12.629,29.532-12.629c27.612-16.785,43.773-37.514,53.175-57.085
			c-24.329,14.08-52.575,22.143-82.707,22.143S562.622,1515.597,538.293,1501.517z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#47861B" d="M786.414,1523.659c-30.132,0-58.377-8.063-82.707-22.143
			c9.401,19.571,25.562,40.3,53.175,57.085c0,0,12.259,12.629,29.532,12.629c17.272,0,29.532-12.629,29.532-12.629
			c27.612-16.785,43.775-37.514,53.177-57.085C844.792,1515.597,816.546,1523.659,786.414,1523.659z"/>
                </g>
                <g>
                    <path fill="#FBEBAD" d="M944.379,1461.197c39.478-55.382,68.821-116.993,87.219-183.124
			c29.743-106.911,20.998-190.077,19.966-198.728c-24.956-123.574-87.747-212.386-186.638-263.961
			c-81.928-42.728-169.578-60.496-212.148-49.112c-91.957,24.592-52.436,958.728,56.051,903.589
			c22.381-11.375,34.203-25.832,34.324-25.958l2.558-2.635l3.141-1.907C829.089,1590.586,894.876,1530.645,944.379,1461.197z"/>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#4B2910" d="M746.784,1048.583c25.819,3.707,47.433-22.003,61.065-33.925
					c10.719-9.377,41.66-28.658,40.711-54.282c-1.244-33.607-29.997-33.379-44.104-30.532
					C771.13,936.566,666.51,1037.059,746.784,1048.583z"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#4B2910" d="M797.673,1201.248c30.36,12.131,93.276,7.608,128.917,3.393
					C1005.864,1122.994,739.53,1105.012,797.673,1201.248z"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#4B2910" d="M743.392,1303.024c-75.916,22.15,37.921,116.623,64.458,118.74
					c27.682,2.209,43.817-13.187,40.711-40.712C845.362,1352.703,767.184,1296.083,743.392,1303.024z"/>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}