import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 81.997 81.997"   {...props}>
            <g>
                <g>
                    <path fill="#56B300" d="M40.739,30.485c0,0,2.792-18.608-14.888-23.727C25.852,6.759,23.061,24.902,40.739,30.485z"/>
                </g>
                <g>
                    <path fill="#56B300" d="M39.946,30.485c0,0-2.792-18.608,14.888-23.727C54.834,6.759,57.625,24.902,39.946,30.485z"/>
                </g>
                <g>
                    <path fill="#56B300" d="M40.533,28.005c0,0-11.977-14.514,0.539-28.005C41.072,0,52.811,14.114,40.533,28.005z"/>
                </g>
                <g>
                    <path fill="#FF9600" d="M53.824,15.745c-7.502-0.294-7.896,2.383-13.002,2.383S36.014,15.4,27.82,15.745
			c-8.831,0.372-25.831,4.706-25.789,28.916c0.026,15.233,7.653,31.177,23.167,32.061c0.213-0.027,0.446-0.032,0.691-0.023
			c3.474,9.054,11.032,3.747,14.933,3.747c3.839,0,11.223,5.149,14.767-3.319c0.677-0.083,1.296-0.128,1.813-0.052
			c15.501-0.566,22.095-16.368,22.563-31.57C79.965,22.784,61.326,16.039,53.824,15.745z"/>
                </g>
            </g>

        </SvgIcon>
    )
}