import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#66AA43" d="M256.087,929.458c-94.013-7.826-133.742-69.938-193.595-111.923
		c40.043-4.359,79.93-21.381,133.097-15.125c29.131,3.429,96.969,44.807,102.848,9.076c4.283-26.028-76.487-43.616-96.798-60.5
		c-48.038-39.93-51.021-111.646-63.524-187.547c86.418,28.273,164.488,43.732,190.572,133.098
		c5.536,18.968-1.396,63.972,36.299,63.524c21.867-32.804-2.408-74.484,0-111.923c2.828-43.977,27.547-80.531,30.25-120.998
		c24.821,42.862,78.088,103.899,84.699,169.396c1.06,10.514,3.43,29.611,0,42.35c-7.236,26.869-44.3,38.798-42.35,78.648
		c0,0,17.922,51.146,22.016,90.038C463.694,946.466,308.444,920.64,256.087,929.458z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#66AA43" d="M771.316,1214.068c-19.057-92.394,29.299-148.054,52.663-217.331
		c15.495,37.179,43.094,70.633,52.115,123.4c4.941,28.912-15.582,105.678,20.354,101.223c26.178-3.247,20.227-85.694,30.683-109.949
		c24.73-57.362,92.683-80.486,161.957-113.927c-2.703,90.885,4.526,170.143-73.828,220.413
		c-16.631,10.67-61.759,16.737-50.681,52.77c37.646,11.708,70.771-23.356,107.364-31.625c42.981-9.712,85.032,3.671,124.613-5.17
		c-34.103,35.921-77.602,104.263-138.562,129.11c-9.787,3.988-27.435,11.656-40.624,11.967c-27.82,0.648-49.733-31.533-87.41-18.402
		c0,0-44,31.643-80.149,46.558C813.662,1418.021,794.57,1261.799,771.316,1214.068z"/>
                <g>
                    <g>
                        <g>
                            <path fill="#720839" d="M887.269,1254.341c0,251.419-188.449,380.181-420.914,380.181
					c-232.463,0-420.913-128.762-420.913-380.181c0-251.417,188.449-530.284,420.913-530.284
					C698.819,724.057,887.269,1002.924,887.269,1254.341z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <path fill="#FECA38" d="M452.408,1529.32c76.208,73.809,176.608,114.456,282.708,114.456
			c106.098,0,206.501-40.647,282.705-114.456c56.144-54.376,94.753-122.548,112.476-196.963H339.935
			C357.656,1406.772,396.266,1474.944,452.408,1529.32z"/>
                    <path fill="none" d="M472.67,1501.909c70.551,68.329,163.756,105.96,262.446,105.96c98.691,0,191.895-37.631,262.442-105.96
			c29.771-28.832,54.261-61.896,72.792-98.276c11.631-22.829,20.761-46.682,27.318-71.275H372.563
			c6.556,24.593,15.687,48.445,27.316,71.275C418.412,1440.012,442.902,1473.077,472.67,1501.909z"/>
                    <path fill="#FFFFFF" d="M1097.669,1332.357c-6.558,24.594-15.688,48.446-27.318,71.275
			c-18.531,36.381-43.021,69.444-72.792,98.276c-70.548,68.329-163.751,105.96-262.442,105.96
			c-98.689,0-191.895-37.631-262.446-105.96c-29.769-28.832-54.258-61.897-72.791-98.276c-11.63-22.83-20.76-46.683-27.316-71.275
			h-32.628l0,0c17.721,74.415,56.331,142.588,112.473,196.965c76.208,73.808,176.608,114.455,282.708,114.455
			c106.098,0,206.501-40.647,282.705-114.455c56.145-54.377,94.754-122.55,112.476-196.965l0,0H1097.669z"/>
                    <path fill="none" d="M452.408,1529.322c76.208,73.808,176.608,114.455,282.708,114.455c106.098,0,206.501-40.647,282.705-114.455
			c56.144-54.378,94.754-122.55,112.474-196.965h-32.626H372.563h-32.628l0,0C357.657,1406.772,396.266,1474.945,452.408,1529.322z"
                    />
                    <path fill="#761342" d="M1130.295,1332.357c-17.72,74.415-56.33,142.587-112.474,196.965
			c-76.204,73.808-176.607,114.455-282.705,114.455c-106.1,0-206.5-40.647-282.708-114.455
			c-56.143-54.377-94.751-122.55-112.473-196.965l0,0h-33.419c42.791,199.232,218.39,348.502,428.601,348.502
			c210.206,0,385.807-149.27,428.598-348.502h-33.417H1130.295z"/>
                    <path fill="#561C10" d="M482.989,1418.626c4.448,12.821,2.674,25.082-3.962,27.385c-6.636,2.301-15.621-6.226-20.068-19.047
			c-4.449-12.825-2.675-25.083,3.961-27.386S478.541,1405.803,482.989,1418.626z"/>

                    <ellipse transform="matrix(0.8322 0.5545 -0.5545 0.8322 942.8553 -88.2828)" fill="#561C10" cx="617.264" cy="1513.387" rx="12.718" ry="24.574"/>

                    <ellipse transform="matrix(1 -0.0069 0.0069 1 -10.7209 5.0438)" fill="#561C10" cx="728.565" cy="1562.531" rx="12.718" ry="24.574"/>

                    <ellipse transform="matrix(0.4636 0.886 -0.886 0.4636 1682.8845 152.063)" fill="#561C10" cx="715.846" cy="1466.011" rx="12.718" ry="24.574"/>

                    <ellipse transform="matrix(1 -0.0068 0.0068 1 -9.6019 4.1321)" fill="#561C10" cx="599.841" cy="1407.109" rx="12.718" ry="24.574"/>

                    <ellipse transform="matrix(0.6511 0.759 -0.759 0.6511 1401.0627 -239.7813)" fill="#561C10" cx="961.352" cy="1404.105" rx="12.718" ry="24.574"/>
                    <path fill="#561C10" d="M844.149,1438.264c8.668,10.441,11.312,22.543,5.909,27.032c-5.405,4.483-16.812-0.344-25.482-10.787
			c-8.667-10.444-11.312-22.546-5.906-27.031C824.073,1422.989,835.48,1427.818,844.149,1438.264z"/>

                    <ellipse transform="matrix(0.9999 -0.01 0.01 0.9999 -15.119 9.266)" fill="#561C10" cx="917.109" cy="1513.385" rx="12.718" ry="24.574"/>

                    <ellipse transform="matrix(0.5529 0.8332 -0.8332 0.5529 1474.4493 22.9423)" fill="#561C10" cx="715.846" cy="1385.417" rx="12.718" ry="24.574"/>
                </g>
            </g>
        </SvgIcon>
    )
}