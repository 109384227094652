import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <g>
                    <path fill="#267500" d="M612.809,645.763c0,0-256.308-222.949-544.461,110.77c-1.43,3.651-2.152,5.661-2.152,5.661
			c352.877-223.781,546.614-34.596,546.614-34.596s167.109,53.601,48.278-71.264C637.454,653.666,612.809,645.763,612.809,645.763z"
                    />
                    <path fill="#359704" d="M612.809,645.763c0,0,24.645,7.903,48.278,10.571c-21.743-22.847-53.046-51.66-96.71-87.878
			C255.655,312.383,88.818,704.267,68.348,756.532C356.501,422.813,612.809,645.763,612.809,645.763z"/>
                </g>
                <g>
                    <path fill="#8C492F" d="M654.429,823.654c-1.707-0.688-3.387-1.503-5.028-2.446c-19.149-11.015-32.476-18.773-21.462-37.924
			c21.798-37.898,27.411-81.604,16.685-129.902c-8.134-36.624-22.771-61.705-22.917-61.947
			c-11.351-18.952-5.189-43.519,13.762-54.87c18.952-11.352,43.518-5.189,54.869,13.761c2.172,3.626,21.526,36.827,32.383,85.712
			c14.98,67.449,6.185,132.159-25.435,187.134C687.215,840.68,672.643,830.999,654.429,823.654z"/>
                </g>
                <path fill="#E36E38" d="M1017.899,1563.55c-210.899,216.36-562.131,216.037-784.5-0.72
		C11.029,1346.075,1.73,994.964,212.629,778.603c83.531-85.694,166.894-113.6,266.458-112.974
		c43.36,0.272,157.929,50.026,208.786,58.705c127.207,21.707,236.536,2.967,332.474,96.483
		C1242.717,1037.574,1228.799,1347.189,1017.899,1563.55z"/>
                <path fill="#F87C40" d="M549.295,1453.1c-217.385-211.896-266.017-530.192-141.342-782.244
		c-69.665,11.497-132.449,43.245-195.324,107.748c-210.899,216.36-201.6,567.472,20.771,784.227
		c191.469,186.638,478.475,212.804,689.174,78.356C787.179,1619.764,655.953,1557.065,549.295,1453.1z"/>
            </g>
        </SvgIcon>
    )
}