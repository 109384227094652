import React from 'react';
import { SvgIcon } from '@mui/material';


export default function( props )
{
    return(
        <SvgIcon viewBox="0 0 1242 2208"   {...props}>
            <g>
                <path fill="#FBC86C" d="M531.236,1659.948c23.063-56.326-79.129-122.573-52.26-188.97c11.766-29.066,29.567-175.912,92.355-214.878
		c42.41-25.905,17.498,63.564-3.435,112.051c-13.003,51.058-26.002,102.119-39.007,153.179
		c52.039,23.188,46.818-18.202,62.924-25.249c5.769,29.907,33.533,56.443,60.11,44.049c66.323-46.195-56.278-191.608-36.455-260.243
		c44.59-71.449,207.067,96.096,164.525,142.274c-96.283,171.927,50.472,204.521,111.863,151.918
		c42.724-37.671,55.447-53.572,72.621-144.989c9.932-51.695,12.229-123.796-15.863-127.105
		c-40.296,5.691-99.85,66.833-141.618,14.124c-33.842-34.674,139.616-86.411,130.271-117.394
		c-85.556-42.667-134.199,65.431-182.966,28.693c-35.309-34.285,84.296-106.698,165.399-58.26
		c28.405-40.208-26.619-30.854-8.562-64.917c-65.657-31.445-132.648,43.361-153.399,11.126
		c-27.251-34.284,94.037-21.271,127.051-55.478c-13.225-5.478-8.054-21.885-2.782-38.35c-77.985,9.071-85.959-55.77-14.567-41.353
		c-25.198-82.755-90.402-75.767-49.696-100.484c-16.088-4.637-9.129-46.165-20.347-67.917
		c-18.971,31.664-42.553,50.311-71.669,41.332c-34.154-28.924,31.49-56.473,45.322-85.681
		c-36.615-11.701-48.006,12.606-70.697-18.002c-22.406-29.622,49.344-36.333,29.785-67.698
		c-29.965-16.772-29.681,17.375-53.132,11.562c2.94,59.406,20.785,103.775-65.935,58.232
		c-34.355-18.29,24.896-145.527,71.934-81.795c15.367-45.801-2.314-52.586-29.873-95.923c-39.17-25.943-15.725-79.667-78.521-69.698
		c-112.05,87.702,159.505,663.062,114.989,681.732c-36.816,41.527-118.707-448.536-156.024-393.369
		c-12.437,64.286,55.273,162.451,23.376,205.029c-35.666,31.371-27.398-143.243-54.57-160.464
		c-51.207-26.158,112.916,374.66-52.103,450.98c-78.397,67.456,102.81-618.8-25.624-707.452
		c-48.187-23.98-103.747,93.878-78.847,121.51c45.107,58.227,48.547,56.01,77.092,102.174
		c59.852,81.206-112.82-66.066-141.398-36.003c10.333,30.388,50.465,15.107,5.563,76.698c18.989,12.053,94.632,63.212,35.131,59.135
		c-11.782-3-23.566-6.002-35.35-9.002c21.877,52.329-20.786,29.382,2.565,88.484c39.193,15.47-4.551,6.26-23.787,44.132
		c3.487,27.931,59.84,78.283-3.218,61.913c-19.77,26.349,20.798,25.706,40.469,35.247c0.185,0.068,0.403,0.174,0.664,0.326
		c-0.218-0.111-0.439-0.218-0.664-0.326c-5.828-2.12,24.166,37.024-31.905,29.672c14.743,60.904-6.359,107.313,16.409,116.639
		c30.493,6.439,31.228,16.864,50.057,3.668c10.191-8.819-42.237-40.933-13.77-31.604c29.71,5.684,47.349,18.543,52.916,38.568
		c6.503,33.389-49.08,10.024-84.586,11.981c-26.379,1.089-27.944,53.375,2.495,59.676c107.388,17.882,126.682-32.461,146.569,20.044
		c19.887,52.505-192.123,20.909-149.453,82.643c12.921,25.803,152.234-44.292,169.8-14.725s-94.973,30.919-138.834,52.481
		C255.497,1531.27,474.686,1798.067,531.236,1659.948z"/>
                <path fill="#704819" d="M531.021,1710.081c96.554-36.693-7.834-141.62,3.654-162.184c56.942,6.175,70.969,37.843,111.828,53.573
		c32.982-23.041,42.793-85.089,85.919-91.049c-14.688,116.21,141.017,153.424,214.882,92.357
		c61.786-52.525,66.16-268.156-1.472-339.146c28.964-197.217-114.969-291.722-118.861-469.419
		c-6.122-63.796-144.104-324.622-234.57-310.675c-48.128,8.769-71.395,59.387-109.488,85.044
		c-4.429-27.369-25.629-44.78-61.695-53.353C316.32,539.675,295.13,679.256,263.722,788.809
		C285.895,1059.235,113.894,1842.326,531.021,1710.081z M322.577,1493.893c43.861-21.563,156.399-22.914,138.834-52.481
		s-156.879,40.527-169.8,14.725c-42.67-61.733,169.34-30.138,149.453-82.643c-19.888-52.505-39.182-2.162-146.569-20.044
		c-30.439-6.301-28.874-58.587-2.495-59.676c35.506-1.957,91.089,21.407,84.586-11.981c-5.567-20.025-23.206-32.885-52.916-38.568
		c-28.468-9.328,23.961,22.785,13.77,31.604c-18.829,13.196-19.563,2.771-50.057-3.668c-22.769-9.326-1.666-55.734-16.409-116.639
		c56.071,7.353,26.077-31.792,31.905-29.672c-19.671-9.541-60.238-8.897-40.469-35.247c63.058,16.37,6.705-33.982,3.218-61.913
		c19.236-37.872,62.98-28.662,23.787-44.132c-23.352-59.103,19.312-36.155-2.565-88.484c11.783,3,23.567,6.003,35.35,9.002
		c59.501,4.077-16.142-47.082-35.131-59.135c44.902-61.591,4.771-46.31-5.563-76.698c28.578-30.063,201.25,117.208,141.398,36.003
		c-28.545-46.165-31.984-43.948-77.092-102.174c-24.9-27.632,30.66-145.491,78.847-121.51
		c128.434,88.652-52.773,774.908,25.624,707.452c165.019-76.32,0.896-477.138,52.103-450.98
		c27.172,17.221,18.904,191.835,54.57,160.464c31.897-42.578-35.813-140.744-23.376-205.029
		c37.317-55.167,119.208,434.896,156.024,393.369c44.516-18.671-227.039-594.03-114.989-681.732
		c62.796-9.969,39.351,43.754,78.521,69.698c27.559,43.337,45.24,50.122,29.873,95.923c-47.038-63.731-106.289,63.505-71.934,81.795
		c86.72,45.543,68.875,1.174,65.935-58.232c23.451,5.813,23.167-28.333,53.132-11.562c19.559,31.365-52.191,38.076-29.785,67.698
		c22.691,30.608,34.082,6.301,70.697,18.002c-13.832,29.208-79.477,56.757-45.322,85.681c29.116,8.979,52.698-9.667,71.669-41.332
		c11.218,21.752,4.259,63.28,20.347,67.917c-40.706,24.717,24.498,17.729,49.696,100.484
		c-71.392-14.417-63.418,50.423,14.567,41.353c-5.271,16.465-10.442,32.872,2.782,38.35
		c-33.014,34.207-154.302,21.194-127.051,55.478c20.751,32.234,87.742-42.572,153.399-11.126
		c-18.058,34.062,36.967,24.708,8.562,64.917c-81.104-48.438-200.708,23.975-165.399,58.26
		c48.767,36.737,97.41-71.36,182.966-28.693c9.345,30.982-164.113,82.72-130.271,117.394
		c41.769,52.709,101.322-8.433,141.618-14.124c28.092,3.31,25.795,75.41,15.863,127.105
		c-17.174,91.417-29.897,107.318-72.621,144.989c-61.392,52.603-208.146,20.009-111.863-151.918
		c42.542-46.179-119.936-213.724-164.525-142.274c-19.823,68.635,102.778,214.048,36.455,260.243
		c-26.577,12.395-54.342-14.142-60.11-44.049c-16.105,7.047-10.885,48.437-62.924,25.249
		c13.005-51.06,26.004-102.121,39.007-153.179c20.933-48.486,45.845-137.956,3.435-112.051
		c-62.788,38.966-80.59,185.812-92.355,214.878c-26.869,66.396,75.323,132.644,52.26,188.97
		C474.686,1798.067,255.497,1531.27,322.577,1493.893z"/>
                <path d="M302.879,1124.85c0.225,0.108,0.446,0.215,0.664,0.326C303.282,1125.023,303.063,1124.918,302.879,1124.85z"/>
            </g>
        </SvgIcon>
    )
}